import React, { useEffect, useState } from 'react';

import axisLogo from './logo-orbit-black.png';
import css from './LoadingScreen.module.css';

const LoadingScreen = ({ loading, setLoading }) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const delay = 1800;
    const totalTime = 2800;
    let interval;
    let timer;

    const loop = () => {
      interval = setInterval(() => {
        if (percent < 100) {
          setPercent((per) => Math.min(per + 1, 100));
        }
      }, totalTime / 100);
    };

    // eslint-disable-next-line prefer-const
    timer = setTimeout(loop, delay);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (percent === 100) {
      setTimeout(() => setLoading(false), 1500);
    }
  }, [loading, percent]);

  return (
    <div data-cy="loading-screen" className={css.loadingScreen}>
      <div className={css.logo}>
        <Logo />
        <div className={css.logoBlanket} />
      </div>
      <div className={css.loadingBar}>
        <div className={css.loadingBarGradient} />
      </div>
      <div className={css.percentContainer}>
        <div className={css.percent}>
          {percent}%<span />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="503"
      height="197"
      fill="none"
      viewBox="0 0 503 197"
    >
      <image x="0" y="45" width="120px" height="120px" href={axisLogo} />
      <path
        fill="#000"
        d="M185.96 69.044L155 133.394h7.74l8.91-18.45h38.79l8.73 18.45h8.64l-30.69-64.35h-11.16zm-11.25 39.6l16.56-34.2 16.2 34.2h-32.76zm89.39-3.96l26.1 28.71h9.9l-29.97-32.85 27.81-31.5h-8.73l-23.58 26.46-24.21-26.46h-9.81l28.08 30.87-30.33 33.48H238l26.1-28.71zm45.838-35.64v64.35h8.37v-64.35h-8.37zm52.321 65.16c4.68 0 8.82-.45 12.51-1.44 3.69-.99 6.75-2.34 9.27-4.05 2.52-1.71 4.41-3.69 5.76-6.03 1.35-2.34 1.98-4.95 1.98-7.74 0-3.15-.63-5.76-1.89-7.92-1.26-2.16-3.15-3.96-5.58-5.4-2.43-1.44-5.49-2.52-9.09-3.33-3.6-.81-7.74-1.44-12.51-1.98-4.5-.54-8.19-1.17-11.07-1.89-2.88-.72-5.13-1.53-6.75-2.43-1.62-.9-2.79-1.89-3.42-3.06-.63-1.17-.99-2.43-.99-3.87 0-3.24 1.53-5.85 4.59-7.74 3.06-1.89 8.01-2.88 14.85-2.88 6.3 0 11.16.81 14.67 2.52s5.76 4.68 6.75 8.91h7.65c-.81-5.85-3.78-10.26-8.91-13.23-5.13-2.97-11.88-4.41-20.25-4.41-4.23 0-8.01.45-11.34 1.35-3.33.9-6.21 2.07-8.55 3.6-2.34 1.53-4.14 3.33-5.4 5.4a12.744 12.744 0 00-1.89 6.75c0 2.88.63 5.31 1.89 7.29 1.26 1.98 3.06 3.69 5.4 5.04 2.34 1.35 5.22 2.43 8.64 3.24 3.42.81 7.38 1.53 11.79 2.07 4.41.54 8.1 1.08 11.07 1.71 2.97.63 5.4 1.35 7.2 2.25 1.8.9 3.06 1.98 3.87 3.33s1.17 2.97 1.17 4.86c0 3.96-1.89 7.02-5.58 9.18-3.69 2.16-9 3.24-15.93 3.24-4.32 0-8.01-.36-10.98-1.08-2.97-.72-5.4-1.71-7.29-2.97-1.89-1.26-3.33-2.7-4.32-4.41-.99-1.71-1.71-3.51-2.07-5.49h-8.19c.45 2.79 1.35 5.49 2.79 8.01 1.44 2.52 3.51 4.68 6.12 6.57 2.61 1.89 5.85 3.33 9.81 4.41 3.96 1.08 8.64 1.62 14.04 1.62h.18z"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.151"
          x2="117.147"
          y1="53.14"
          y2="168.135"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.021" stopColor="#1A1A1A" stopOpacity="0" />
          <stop offset="0.182" stopColor="#1A1A1A" />
          <stop offset="0.828" stopColor="#1A1A1A" />
          <stop offset="0.974" stopColor="#1A1A1A" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="4.584"
          x2="130.641"
          y1="155.996"
          y2="159.248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="0.427" stopColor="#fff" stopOpacity="0" />
          <stop offset="0.557" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="275"
          x2="275"
          y1="142"
          y2="174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808285" />
          <stop offset="1" stopColor="#808285" stopOpacity="0.3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
