/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Loading } from '@axis/xyz.app.loading';

import querySearchEntities from '../../../queries/searchEntities';
import css from './SearchActors.module.css';
import notify from '../../../lib/notify';
import { useApolloClient } from '../../../hooks/useApolloClient';
import EntityPill from '../../EntityPill/EntityPill';
import SearchInput from '../components/SearchInput/SearchInput';

const VISIBLE_RESULTS = 10;

const fetchSearchEntityResults = ({
  client,
  name,
  topics,
  filterIndustries,
  filterEntities,
  filterCategories,
  bookmarked = false,
}) => {
  return client
    .query({
      query: querySearchEntities,
      variables: {
        name,
        topics,
        filterIndustries,
        filterEntities,
        filterCategories,
        bookmarked,
      },
    })
    .then(({ data }) => data.searchEntities.entities);
};

const getBounds = (ref) => {
  if (!ref || !ref.current)
    return {
      top: 0,
      height: 0,
      left: 0,
      width: 0,
      bottom: 0,
      right: 0,
    };
  return ref.current.getBoundingClientRect();
};

const SearchActors = ({ entities = [], setEntities, customClass }) => {
  const client = useApolloClient();

  const ref = useRef();
  const pickerRef = useRef();

  const [isOpen, setOpen] = useState(false);

  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [isLoadingAutocompleteResults, setLoadingAutocompleteResults] =
    useState(false);

  const [search, setSearch] = useState('');

  useEffect(() => {
    const scrollHandler = () => {
      const root = document.documentElement;
      const { top, left, width } = getBounds(ref);
      const xCoord = left + width;
      const yCoord = top;
      const xOffset = xCoord + 10;
      const yOffset = yCoord - 200;
      root.style.setProperty('--feed-entity-x', `${xOffset}px`);
      root.style.setProperty('--feed-entity-y', `${yOffset}px`);
    };
    scrollHandler();
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, [isOpen]);

  useEffect(() => {
    setSearch('');
  }, [isOpen]);

  useEffect(() => {
    const handler = (event) => {
      const clickFilter = ref.current && ref.current.contains(event.target);
      if (!clickFilter) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, []);

  useEffect(() => {
    if (search.length > 1) {
      setLoadingAutocompleteResults(true);
      fetchSearchEntityResults({
        client,
        name: search,
        topics: [],
        filterIndustries: [],
        filterEntities: [],
      })
        .then((entities) => {
          /*
           * We only show the top 10 results
           * We need to watch out for duplicates
           * We don't have a "score" yet indicating the relevance of the result
           */
          const newResults = entities
            .filter((entity, index, entities) => {
              const firstIndex = entities.findIndex((e) => e.id === entity.id);
              return firstIndex === index;
            })
            .slice(0, VISIBLE_RESULTS);
          setAutocompleteResults(newResults);
        })
        .catch(() => {
          notify.error('There was an error retrieving the search results.');
        })
        .finally(() => {
          setLoadingAutocompleteResults(false);
        });
    }
  }, [search, client]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEntityRemove = (e) => {
    e.preventDefault();
    setEntities([]);
  };

  const handleEntityClick = (entity) => {
    setEntities([entity]);
    setOpen(false);
  };

  const clearAll = () => {
    setEntities([]);
  };

  const hasVisibleEntities = autocompleteResults.length > 0;

  return (
    <div
      ref={ref}
      data-cy="feed-entity-filter"
      className={`${css.main} ${customClass}`}
    >
      <SearchInput
        query={search}
        dataCy="feed-actor-filter"
        placeholder="Search actors"
        isLoading={false}
        onQueryChange={setSearch}
        onClick={handleOpen}
        clearAll={entities.length > 0 ? clearAll : undefined}
      />

      <div className={css.selected}>
        {entities.map((option) => (
          <EntityPill
            key={option.id}
            name={option.name}
            shortName={option.shortestName}
            image={option.image}
            type={option.type || option.entityType}
            size="small"
            inSubscription={option.inSubscription}
            onClose={handleEntityRemove}
          />
        ))}
      </div>

      {isOpen && search.length > 1 && (
        <div ref={pickerRef} className={css.entityPicker}>
          <div className={css.options}>
            {autocompleteResults.map((result) => (
              <div
                key={result.id}
                className={css.option}
                onClick={() => handleEntityClick(result)}
                data-cy="feed-entity-filter-option"
                data-type="feed-entity-focus-item"
              >
                <EntityPill
                  name={result.name}
                  shortName={result.shortestName}
                  image={result.image}
                  type={result.type}
                  inSubscription={result.inSubscription}
                />
              </div>
            ))}
            {!isLoadingAutocompleteResults && !hasVisibleEntities && (
              <div className={css.noResults} data-type="children">
                <p
                  data-cy="no-results-search-for-filter-search"
                  className={css.noResultsSearch}
                >
                  No entities match &quot;{search}&quot;
                </p>
              </div>
            )}
            {isLoadingAutocompleteResults && (
              <div className={css.loadingArea}>
                <Loading className={css.loading} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchActors;
