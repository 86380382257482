import { gql } from '@apollo/client';

const query = gql`
  query searchEventsAndRegulations(
    $name: String
    $notes: Boolean
    $filterCategories: FilterCategories
    $compliance: Boolean
  ) {
    searchEventsAndRegulations(
      name: $name
      notes: $notes
      filterCategories: $filterCategories
      compliance: $compliance
    ) {
      regulations {
        skip
        limit
        data {
          id
          legalName
          summarizedName
          notesCount
          isCompliant
          complianceNote
          complianceUpdatedAt
        }
      }
    }
  }
`;

export default query;
