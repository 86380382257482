import { uniqueId } from 'lodash';
import { useHistory } from 'react-router-dom';
import React, { useRef, useState } from 'react';

import Input from '../Input/Input';
import css from './SearchBar.module.css';
import Suggestions from './Suggestions/Suggestions';
import useLocalStorage from '../../hooks/useLocalStorage';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import Smart from '../../../icons/Smart';
import AdvancedSearch from '../../../icons/AdvancedSearch';

const placeholders = {
  true: 'What would you like to know?',
  false:
    "Use '#' to filter for industries and countries, and '@' to filter for actors",
};

function SearchBar({ onSearch }) {
  const history = useHistory();
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [question, setQuestion] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [isSmartSearch, setIsSmartSearch] = useLocalStorage(
    'smart-search',
    false,
  );

  const cleanSearchBarText = () => setQuestion('');

  const cleanFilters = () => setFilterOptions([]);

  const focusInput = () => inputRef.current.focus();

  const onEscapePress = () => {
    cleanSearchBarText();
    focusInput();
  };

  const runAdvancedSearch = (filters) => {
    /**
     * This strategy saves the filters on Session Storage
     * to be used in adv search.
     *
     * TODO: replace this strategy when migrate adv search page
     */

    const params = new URLSearchParams();
    const hasFilters = filters.length > 0;

    if (hasFilters) {
      const uuid = uniqueId('search-');
      sessionStorage.setItem(
        uuid,
        JSON.stringify({
          industries: filterOptions.filter(
            ({ pillType }) => pillType === 'industry',
          ),
          countries: filterOptions.filter(
            ({ pillType }) => pillType === 'country',
          ),
          entities: filterOptions.filter(
            ({ pillType }) => pillType === 'entity',
          ),
        }),
      );
      params.set('queryId', uuid);
    }

    if (question.trim()) {
      params.set('search', question);
    }

    if (!params.get('leftPanel')) {
      params.set('isFilterOpen', true);
    }

    history.push(`/search?${params.toString()}`);
  };

  const runSmartSearch = (text) => {
    history.push(`/ai-search?question=${text}`);
  };

  const onKeyDown = (event) => {
    if (event.key !== 'Enter') return;

    onSearch?.();

    if (isSmartSearch && question !== '') {
      runSmartSearch(question);
      return;
    }

    runAdvancedSearch(filterOptions);
  };

  const onSwitchChanged = (value) => {
    setIsSmartSearch(value);
    focusInput();
    cleanFilters();
    cleanSearchBarText();
  };

  const onItemSelected = (option) => {
    if (!option) return;

    setFilterOptions((prev) => [...prev, option]);
    cleanSearchBarText();
    focusInput();
  };

  const onRemoveFilterClicked = (filter) => {
    setFilterOptions((current) => current.filter(({ id }) => id !== filter.id));
  };

  const searchPlaceHolder = placeholders[isSmartSearch];

  return (
    <div className={css.input} ref={containerRef}>
      <div className={css.toggle}>
        <div
          className={css.toggleOption}
          data-active={isSmartSearch}
          onClick={() => {
            onSwitchChanged(true);
          }}
        >
          <Smart /> Ask anything with AI
        </div>
        <div
          className={css.toggleOption}
          data-active={!isSmartSearch}
          onClick={() => onSwitchChanged(false)}
        >
          <AdvancedSearch /> Keyword search
        </div>
      </div>

      <Input
        data-cy="main-search-textbox"
        value={question}
        data-selectable={0}
        inputRef={inputRef}
        onKeyDown={onKeyDown}
        showIcon={false}
        placeholder={searchPlaceHolder}
        autoFocus
        className={css.customInput}
        onChange={(e) => setQuestion(e.target.value)}
        leftSlot={
          <SelectedFilters
            filters={filterOptions}
            onRemoveFilterClicked={onRemoveFilterClicked}
          />
        }
      >
        {!isSmartSearch && (
          <Suggestions
            containerRef={containerRef}
            question={question}
            onEscape={onEscapePress}
            onItemSelected={onItemSelected}
          />
        )}
      </Input>
    </div>
  );
}

export default SearchBar;
