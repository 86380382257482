import React from 'react';
import { sendEvent } from '../../../../contexts/AnalyticsTrackingContext';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import css from './ResultCards.module.css';
import { getSearchResultAction } from '../../../../lib/search';
import ResultRegulationCard from './ResultRegulationCard/ResultRegulationCard';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import ResultEventCard from './ResultEventCard/ResultEventCard';
import ResultQuoteCard from './ResultQuoteCard/ResultQuoteCard';
import AppointmentCard from '../../../../v2/components/Cards/AppointmentCard/AppointmentCard';

const ResultFacetRegulation = ({ facet }) => {
  const {
    id,
    summarizedName,
    issuer,
    authorizedIssuer,
    industries,
    jurisdiction,
    legalName,
    summary,
    createdAt,
    startDate,
    source,
    attachment,
    notesCount,
    isCompliant,
    bullets,
    regulationType,
  } = facet;

  return (
    <ResultRegulationCard
      id={id}
      summarizedName={summarizedName}
      issuer={issuer}
      authorizedIssuer={authorizedIssuer}
      industries={industries}
      jurisdiction={jurisdiction}
      legalName={legalName}
      summary={summary}
      createdAt={createdAt}
      date={startDate}
      source={source}
      attachment={attachment}
      notesCount={notesCount}
      isCompliant={isCompliant}
      bullets={bullets}
      regulationType={regulationType}
    />
  );
};

export const ResultEntity = ({ entity = {} }) => {
  const router = useRouteInformation();

  const handleEntityClick = () => {
    sendEvent('advanced_search_entity_click', {
      description: 'Click on a result from the search page',
      entityId: entity.id,
      entityName: entity.name,
    });

    getSearchResultAction({ entity, router });
  };

  return (
    <div
      data-cy="search-results-entity"
      className={css.result}
      onClick={handleEntityClick}
    >
      <div data-cy="search-results-entity-data" className={css.resultInfo}>
        <div className={css.resultTitleContainer}>
          <EntityPill
            className={css.resultPill}
            name={entity.name}
            shortName={entity.shortestName}
            image={entity.image}
            type={entity.type}
            inSubscription={entity.inSubscription}
            isConnected={entity.isConnected}
            size="medium"
          />
        </div>
        {entity.primaryRoleTitle && (
          <p
            data-cy="search-results-entity-description"
            className={css.entityDescription}
          >
            {entity.primaryRoleTitle}
          </p>
        )}
      </div>
    </div>
  );
};

export const ResultFacet = ({ facet = {}, type }) => {
  if (type === 'regulations') {
    return <ResultFacetRegulation facet={facet} />;
  }
  if (type === 'events') {
    return <ResultEventCard facet={facet} />;
  }
  if (type === 'quotes') {
    return <ResultQuoteCard facet={facet} />;
  }
  if (type === 'appointments') {
    return <AppointmentCard appointment={facet} />;
  }
  return null;
};
