import React from 'react';

import { format } from 'date-fns';
import css from './Welcome.module.css';
import { useIdentity } from '../../contexts/IdentityContext';

const date = format(Date.now(), 'EEEE, MMMM d');

function Welcome() {
  const { user } = useIdentity();

  return (
    <div className={css.main}>
      <h1>Hello, {user.name}</h1>
      <h2>{date}</h2>
    </div>
  );
}

export default Welcome;
