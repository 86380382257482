import React from 'react';

import css from './RemoveConnection.module.css';
import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../Buttom/Buttom';
import EntityPill from '../../../../../components/EntityPill/EntityPill';

function RemoveConnection({ onClose, entity, note, onRemove }) {
  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Remove personal connection</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      <div className={css.message}>
        <div>
          Are you sure you want to remove
          <EntityPill
            name={entity.name}
            type={entity.type}
            isConnected={entity.isConnected}
            image={entity.image}
            shortName={entity.shortestName}
            size="small"
          />
          from your
        </div>
        personal connections?
      </div>

      {note && (
        <textarea
          className={css.textarea}
          type="text-area"
          disabled
          value={note}
        />
      )}

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="danger" onClick={onRemove}>
          Remove
        </Button>
      </div>
    </div>
  );
}

export default RemoveConnection;
