import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';

import { Link } from 'react-router-dom';
import Helper from '../../../icons/Helper';
import SetupState from './SetupState/SetupState';
import css from './DailyBriefWrapper.module.css';
import SetupPdbModal from './SetupPdbModal/SetupPdbModal';
import GET_PDB_SETUP from '../../../queries/getUserPdbSetup';
import { ROUTES } from '../../shared/routes';

function DailyBriefWrapper() {
  const [isOpen, setIsOpen] = useState();
  const { data, loading, refetch } = useQuery(GET_PDB_SETUP, {
    fetchPolicy: 'cache-and-network',
  });

  const onPdbUpdated = () => {
    setIsOpen(false);
    refetch();
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const pdbConfig = data?.getUserPdb?.pdb;

  const summary = useMemo(() => {
    if (pdbConfig?.bulletin?.shortSummary)
      return pdbConfig.bulletin?.shortSummary;

    return '';
  }, [data]);

  if (!summary && loading) return <LoadingState />;

  return (
    <>
      {!pdbConfig && <SetupState openModal={openModal} />}

      {pdbConfig && (
        <div className={css.card}>
          <div className={css.header}>
            <div className={css.title}>Personal Daily Brief</div>

            <Link className={css.seeFull} to={ROUTES.PDB}>
              See full
            </Link>
          </div>
          <div className={css.content}>
            <h2>{summary || 'Your PDB will be available soon...'}</h2>
            <div className={css.bottom}>
              <Helper />
              Based on your background, interests, countries, and industries.
              <div onClick={() => setIsOpen(true)}>Change Preferences.</div>
            </div>
          </div>
        </div>
      )}

      <SetupPdbModal
        config={pdbConfig}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSave={onPdbUpdated}
      />
    </>
  );
}

export default DailyBriefWrapper;

function LoadingState() {
  return (
    <div className={css.card}>
      <div style={{ height: '15%', width: '30%' }} className="skeleton-v2" />
      <div style={{ height: '60%' }} className="skeleton-v2" />
      <div style={{ height: '15%' }} className="skeleton-v2" />
    </div>
  );
}
