import { gql } from '@apollo/client';

const query = gql`
  query searchEntitiesCount(
    $name: String
    $filterIndustries: [ID]
    $topics: [ID]
    $filterEntities: [ID]
    $filterActorTypes: [CountActorTypes]
    $bookmarked: Boolean
    $connected: Boolean
    $compliance: Boolean
    $notes: Boolean
  ) {
    searchEntitiesCount(
      name: $name
      filterIndustries: $filterIndustries
      filterActorTypes: $filterActorTypes
      topics: $topics
      filterEntities: $filterEntities
      bookmarked: $bookmarked
      connected: $connected
      notes: $notes
      compliance: $compliance
    ) {
      categories {
        total
        type
      }
    }
  }
`;

export default query;
