/* eslint-disable */
import { isEqual } from 'lodash';
import { getIdList } from '../../v2/components/Filter';

export const getEventAndRegulationFilterCategories = (
  type,
  pageInfo,
  regulationTypes,
) => {
  if (type === 'all') return;

  const categories = {
    [type]: {
      ...pageInfo,
    },
  };

  if (type === 'regulations') {
    categories[type].regulationTypes = regulationTypes;
  }

  return categories;
};

export const getEntitiesfilterCategories = (actorTypes, pageInfo) => {
  const mainObject = {};

  actorTypes.forEach((key) => {
    mainObject[key] = pageInfo;
  });

  return mainObject;
};

export const isFilterEqual = (previousState, newState) => {
  if (!previousState) return false;

  const areIndustriesEqual = isEqual(
    getIdList(previousState.industries),
    getIdList(newState.industries),
  );
  const areCountriesEqual = isEqual(
    getIdList(previousState.countries),
    getIdList(newState.countries),
  );
  const areEntitiesEqual = isEqual(
    getIdList(previousState.entities),
    getIdList(newState.entities),
  );

  const areDateEqual = isEqual(
    previousState.dates,
    newState.dates
  )

  const isSearchTextEqual = previousState.search === newState.search;

  return (
    areIndustriesEqual &&
    areCountriesEqual &&
    areEntitiesEqual &&
    isSearchTextEqual &&
    areDateEqual
  );
};
