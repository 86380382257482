import React, { memo } from 'react';
import BriefPill from '../../../../components/BriefPill/BriefPill';
import EntityPill from '../../../../components/EntityPill/EntityPill';

import css from './SelectedFilters.module.css';

function SelectedFilters({ filters, onRemoveFilterClicked }) {
  return (
    <>
      {filters.map((filter) => {
        if (['country', 'industry'].includes(filter.pillType)) {
          return (
            <BriefPill
              key={filter.id}
              name={filter.name}
              className={css.brief}
              isGeography={filter.pillType === 'country'}
              onClose={() => onRemoveFilterClicked(filter)}
            />
          );
        }

        if (filter.pillType === 'entity') {
          return (
            <EntityPill
              key={filter.id}
              id={filter.id}
              autoOpenDossier
              name={filter.name}
              type={filter.type}
              className={css.pill}
              image={filter.image}
              shortName={filter.shortestName}
              onClose={() => onRemoveFilterClicked(filter)}
            />
          );
        }

        return null;
      })}
    </>
  );
}

export default memo(SelectedFilters);
