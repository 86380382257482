import React from 'react';

const SolidExplore = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5C5.50954 7.5 5.98348 7.34756 6.3787 7.0858L9.9142 10.6213C9.65244 11.0165 9.5 11.4905 9.5 12C9.5 12.5095 9.65244 12.9835 9.9142 13.3787L6.37869 16.9142C5.98348 16.6524 5.50954 16.5 5 16.5C3.61929 16.5 2.5 17.6193 2.5 19C2.5 20.3807 3.61929 21.5 5 21.5C6.38071 21.5 7.5 20.3807 7.5 19C7.5 18.4905 7.34756 18.0165 7.0858 17.6213L10.6213 14.0858C11.0165 14.3476 11.4905 14.5 12 14.5C12.5095 14.5 12.9835 14.3476 13.3787 14.0858L16.9142 17.6213C16.6524 18.0165 16.5 18.4905 16.5 19C16.5 20.3807 17.6193 21.5 19 21.5C20.3807 21.5 21.5 20.3807 21.5 19C21.5 17.6193 20.3807 16.5 19 16.5C18.4905 16.5 18.0165 16.6524 17.6213 16.9142L14.0858 13.3787C14.3476 12.9835 14.5 12.5095 14.5 12C14.5 11.4905 14.3476 11.0165 14.0858 10.6213L17.6213 7.0858C18.0165 7.34756 18.4905 7.5 19 7.5C20.3807 7.5 21.5 6.38071 21.5 5C21.5 3.61929 20.3807 2.5 19 2.5C17.6193 2.5 16.5 3.61929 16.5 5C16.5 5.50954 16.6524 5.98348 16.9142 6.37869L13.3787 9.9142C12.9835 9.65244 12.5095 9.5 12 9.5C11.4905 9.5 11.0165 9.65244 10.6213 9.9142L7.0858 6.3787C7.34756 5.98348 7.5 5.50954 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5Z"
      fill="#0E0F10"
    />
  </svg>
);

export default SolidExplore;
