import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 18.333c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.176V3.333c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49h6.667l5 5v10c0 .459-.163.851-.49 1.178-.326.326-.718.49-1.177.49h-10zM11.334 7.5H15.5l-4.166-4.167V7.5z"
        fill="#42474D"
      />
    </svg>
  );
}

export default SvgComponent;
