import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={21}
        height={20}
      >
        <path fill="#D9D9D9" d="M0.5 0H20.5V20H0.5z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M4.667 17.5V4.167c0-.459.163-.851.49-1.177.326-.327.718-.49 1.177-.49h8.333c.458 0 .85.163 1.177.49.327.326.49.718.49 1.177V17.5L10.5 15l-5.833 2.5z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
