import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DossierLayout from '../../components/DossierLayout/DossierLayout';

import Feed from '../../pages/Feed/Feed';
import Explore from '../../pages/Explore/Explore';
import Account from '../../pages/Account/Account';
import AdvancedSearch from '../../pages/Search/Search';

import FullStoryRoute from '../../router/FullStoryRoute';
import ProtectAuthorized from '../../router/ProtectAuthorized';
import ProtectAccess from '../../router/ProtectAccess';

import { AnalyticsTrackingContext } from '../../contexts/AnalyticsTrackingContext';

import AppV2 from '../../v2/App';
import HomeV2 from '../../v2/pages/Home/Home';
import Search from '../../v2/pages/Search/Search';
import { ROUTES } from '../../v2/shared/routes';
import Pdb from '../../v2/pages/Pdb/pdb';

const RouteWrapper = ({ children }) => (
  <ProtectAccess>
    <DossierLayout>
      <FullStoryRoute />
      {children}
    </DossierLayout>
  </ProtectAccess>
);

const AppRoutesV2 = () => {
  return (
    <AppV2>
      <RouteWrapper>
        <Switch>
          <Route exact path="/" component={HomeV2} />
          <Route exact path={ROUTES.FEED}>
            <Feed />
          </Route>
          <Route exact path={ROUTES.PROFILE}>
            <Account />
          </Route>
          <Route exact path={ROUTES.NETWORK}>
            <Explore />
          </Route>
          <Route exact path={ROUTES.AI_SEARCH}>
            <Search />
          </Route>

          <Route exact path={ROUTES.ADV_SEARCH}>
            <AdvancedSearch />
          </Route>
          <Route exact path={ROUTES.PDB}>
            <Pdb />
          </Route>
        </Switch>
      </RouteWrapper>
    </AppV2>
  );
};

const AxisApp = () => {
  return (
    <ProtectAuthorized>
      <AnalyticsTrackingContext>
        <Route path="/" component={AppRoutesV2} />
      </AnalyticsTrackingContext>
    </ProtectAuthorized>
  );
};

export default AxisApp;
