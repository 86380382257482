import React from 'react';
import css from './Score.module.css';

const ImpactType = {
  Positive: 'Positive',
  Negative: 'Negative',
  Neutral: 'Neutral',
};

const getImpactType = (score) => {
  if (score === 0) return ImpactType.Neutral;

  if (score > 0) return ImpactType.Positive;

  return ImpactType.Negative;
};

const getImpactSimbol = (score) => {
  if (score === 0) return '';

  if (score > 0) return '+';

  return '-';
};

function Score({ score, size, hideText, customStyles }) {
  if (typeof score !== 'number' || Number.isNaN(score)) return null;
  const text = Math.abs(score);
  const impact = getImpactType(score);
  const simbol = getImpactSimbol(score);

  return (
    <div className={css.main} data-impact={impact} data-size={size}>
      {!hideText && <span className={css.impact}>Business Relevance:</span>}

      <div className={css.badge} style={customStyles}>
        {simbol} {text}
      </div>
    </div>
  );
}

export default Score;
