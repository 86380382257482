import React, { useState } from 'react';
import { formatDate } from '../../../../../../lib/date';
import { useRouteInformation } from '../../../../../../contexts/RouteInformationContext';
import EventCard from '../../../../../../components/EventCard/EventCard';

const EventUpdate = ({ update }) => {
  const router = useRouteInformation();

  const {
    id,
    title,
    date,
    sources,
    entities,
    industries,
    countries,
    landscapes,
    notesCount,
    summary,
    bullets,
    magnitude,
    sentiment,
    quotes,
    language,
  } = update;

  const [localNotesCount, setLocalNotesCount] = useState(notesCount);

  const handleEntityClick = (entity) => {
    router.toggleDossier({ dossierEntityId: entity, dossierType: 'entity' });
  };

  return (
    <EventCard
      id={id}
      sentiment={sentiment}
      magnitude={magnitude}
      title={title}
      summary={summary}
      bullets={bullets}
      date={formatDate(date)}
      sources={sources}
      entities={entities}
      briefs={industries}
      countries={countries}
      landscapes={landscapes}
      onEntityClick={handleEntityClick}
      notesCount={localNotesCount}
      handleReloadDossier={() => setLocalNotesCount(localNotesCount + 1)}
      quotes={quotes}
      language={language}
    />
  );
};

export default EventUpdate;
