import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={21}
        height={20}
      >
        <path fill="#D9D9D9" d="M0.5 0H20.5V20H0.5z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M1.5 17.134v-2.56c0-.504.127-.976.383-1.418A2.512 2.512 0 012.94 12.15a13.957 13.957 0 012.587-1.007c.96-.274 2.018-.411 3.173-.411.45 0 .889.023 1.316.069.428.045.844.114 1.249.205L9.69 12.607a2.659 2.659 0 00-.484-.046H8.7c-1.065 0-2.021.13-2.869.389-.847.26-1.541.541-2.081.846a.882.882 0 00-.45.777v.732h5.625l1.8 1.83H1.5zm12.195.366l-3.105-3.155 1.26-1.28 1.845 1.874 4.545-4.619 1.26 1.28-5.805 5.9zM8.7 9.817c-.99 0-1.837-.358-2.542-1.074A3.552 3.552 0 015.1 6.159c0-1.006.353-1.868 1.058-2.584C6.861 2.858 7.71 2.5 8.7 2.5c.99 0 1.838.358 2.543 1.075A3.552 3.552 0 0112.3 6.159a3.552 3.552 0 01-1.058 2.584c-.705.716-1.552 1.074-2.542 1.074zm0-1.829c.495 0 .919-.18 1.271-.537.353-.359.529-.79.529-1.292 0-.503-.176-.934-.529-1.292A1.72 1.72 0 008.7 4.33c-.495 0-.919.179-1.271.537A1.776 1.776 0 006.9 6.159c0 .503.176.933.529 1.292a1.72 1.72 0 001.271.537z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
