import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={21}
        height={20}
      >
        <path fill="#D9D9D9" d="M0.5 0H20.5V20H0.5z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M2.167 17.5v-1.667h7.5V6.521A2.432 2.432 0 018.147 5H5.5L8 10.832c0 .695-.284 1.285-.854 1.771-.57.486-1.257.73-2.062.73-.806 0-1.493-.244-2.063-.73-.57-.486-.854-1.076-.854-1.77L4.667 5H3V3.333h5.146c.167-.486.465-.885.896-1.198.43-.312.917-.468 1.458-.468.542 0 1.028.156 1.459.468.43.313.729.712.896 1.198H18V5h-1.666l2.5 5.833c0 .695-.285 1.285-.854 1.771-.57.486-1.258.73-2.063.73-.806 0-1.493-.244-2.063-.73-.569-.486-.854-1.076-.854-1.77L15.5 5h-2.646a2.433 2.433 0 01-1.52 1.52v9.313h7.5V17.5H2.167zm12.188-6.667h3.125l-1.563-3.625-1.563 3.625zm-10.834 0h3.125L5.084 7.208 3.52 10.833zM10.501 5c.235 0 .434-.08.594-.24.159-.16.239-.357.239-.593a.809.809 0 00-.24-.594.809.809 0 00-.594-.24.806.806 0 00-.593.24.807.807 0 00-.24.594c0 .236.08.434.24.594.16.16.357.24.593.24z"
          fill="#42474D"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
