import React, { useState } from 'react';
import { useRouteInformation } from '../../../../../../contexts/RouteInformationContext';
import { trimText } from '../../../../../../lib/text';
import { formatDate, isSameDate } from '../../../../../../lib/date';
import { formatRegulationSummary } from '../../../../../../lib/extractBullets';
import RegulationCard from '../../../../../../components/RegulationCard/RegulationCard';

const RegulationUpdate = ({ update }) => {
  const router = useRouteInformation();

  const {
    id,
    notesCount,
    legalName,
    title,
    summary,
    date,
    createdAt,
    updatedAt,
    source,
    attachment,
    issuer,
    authorizedIssuer,
    industries,
    jurisdiction,
    isCompliant,
    bullets,
    regulationType,
  } = update;

  const firstUpdate = formatDate(createdAt);
  const lastUpdate = formatDate(updatedAt);

  const isNew = isSameDate(firstUpdate, lastUpdate);

  const [isExpanded, setExpanded] = useState(false);

  const clearSummary = formatRegulationSummary(summary);

  const visibleSummary = isExpanded
    ? clearSummary
    : trimText(clearSummary, 100);

  const sources = [source, attachment].filter(Boolean);

  const handleEntityClick = (dossierEntityId) => {
    router.toggleDossier({ dossierEntityId, dossierType: 'entity' });
  };

  return (
    <RegulationCard
      id={id}
      bullets={bullets}
      createdAt={createdAt}
      date={date}
      issuer={issuer}
      authorizedIssuer={authorizedIssuer}
      jurisdiction={jurisdiction}
      legalName={legalName}
      sources={sources}
      summarizedName={title}
      isExpanded={isExpanded}
      handleEntityClick={handleEntityClick}
      setExpanded={setExpanded}
      industries={industries}
      notesCount={notesCount}
      isCompliant={isCompliant}
      visibleSummary={visibleSummary}
      isNew={isNew}
      regulationType={regulationType}
      page="feed"
    />
  );
};

export default RegulationUpdate;
