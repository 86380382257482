import React, { useEffect } from 'react';

import css from './Suggestions.module.css';
import LoadingState from './LoadingState';
import useSuggestions from './useSuggestions';
import useDebounce from '../../../../hooks/useDebounce';
import useArrowNaviagtion from '../../../hooks/useArrowNavigation';
import BriefPill from '../../../../components/BriefPill/BriefPill';
import EntityPill from '../../../../components/EntityPill/EntityPill';

function Suggestions({ question, onItemSelected, onEscape, containerRef }) {
  const searchTerm = useDebounce(question, 500);
  const { runSuggestions, suggestions, isLoading } = useSuggestions();
  useArrowNaviagtion({ onEscape, containerRef });

  useEffect(() => {
    runSuggestions(searchTerm);
  }, [searchTerm]);

  const onKeyDown = (event, entity) => {
    if (event.key === 'Enter') {
      onItemSelected(entity);
    }
  };

  if (isLoading) return <LoadingState />;
  if (question === '' || suggestions.length === 0) return null;

  return (
      <div className={css.main}>
        <h2>Suggested</h2>
        <div data-cy="searchbox-suggestions">
          {suggestions.map((suggestion, index) => (
            <Item
              suggestion={suggestion}
              tabIndex={0}
              key={suggestion.id}
              className={css.suggestion}
              data-selectable={index + 1}
              onClick={() => onItemSelected(suggestion)}
              onKeyDown={(e) => onKeyDown(e, suggestion)}
            />
          ))}
      </div>
    </div>
  );
}

export default Suggestions;

const Item = ({ suggestion, ...props }) => {
  return (
    <div {...props}>
      {suggestion.pillType === 'entity' && (
        <EntityPill
          size="small"
          name={suggestion.name}
          type={suggestion.type}
          className={css.pill}
          image={suggestion.image}
          shortName={suggestion.shortestName}
        />
      )}

      {['country', 'industry'].includes(suggestion.pillType) && (
        <BriefPill
          name={suggestion.name}
          className={css.brief}
          isGeography={suggestion.pillType === 'country'}
        />
      )}
    </div>
  );
};
