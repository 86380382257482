import React, { memo, useState } from 'react';
import { Popover } from 'react-tiny-popover';

import { Link } from 'react-router-dom';
import Logout from '../../../icons/Logout';
import Outlink from '../../../icons/Link';
import useLogout from '../../../hooks/useLogout';

import css from './Avatar.module.css';

function Avatar({ user }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const logout = useLogout();

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['top']}
      containerStyle={{ zIndex: 99 }}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <div className={css.menu} onClick={() => setIsPopoverOpen(false)}>
          <div className={css.header}>
            <div className={css.name}>{user.name}</div>
            <div className={css.email}>{user.email}</div>
          </div>
          <div className={css.divider} />
          <ul>
            {/* Temporarily removed */}
            {/* <li>
              <Link to="/profile" data-cy="avatar-navigate-profile">
                <Account /> Profile
              </Link>
            </li> */}
            <li data-cy="avatar-navigate-guide">
              <a
                rel="noreferrer"
                href="https://axisxyz.notion.site/Axis-Start-Guide-7e1bf4080a6e4aaa8008c03959a16423"
                target="_blank"
              >
                <Outlink /> Starter Guide
              </a>
            </li>

            <li data-cy="avatar-navigate-logout">
              <Link to="/" onClick={logout}>
                <Logout /> Log out
              </Link>
            </li>
          </ul>
        </div>
      }
    >
      <button
        type="button"
        data-cy="avatar"
        data-testid="avatar"
        className={css.main}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        {user.name.charAt(0)}
      </button>
    </Popover>
  );
}

export default memo(Avatar);
