import React from 'react';

import css from './BaseCard.module.css';
import { formatDate } from '../../../../lib/date';
import Source from '../../Source/Source';

function Root({ children, className, ...props }) {
  return (
    <div {...props} className={`${css.card} ${className}`}>
      {children}
    </div>
  );
}

function Row({ children, className, ...props }) {
  return (
    <div {...props} className={`${css.row} ${className}`}>
      {children}
    </div>
  );
}

function LeftColumn({ children, className, ...props }) {
  return (
    <div {...props} className={`${css.leftColumn} ${className}`}>
      {children}
    </div>
  );
}

function RightColumn({ children, className, ...props }) {
  return (
    <div {...props} className={`${css.rightColumn} ${className}`}>
      {children}
    </div>
  );
}

function DateRow({ date, className, ...props }) {
  if (!date) {
    return null;
  }

  const formatedDate = formatDate(date);
  return (
    <div {...props} className={`${css.dateRow} ${className}`}>
      {formatedDate}
    </div>
  );
}

function Title({ children, className, ...props }) {
  return (
    <div {...props} className={`${css.title} ${className}`}>
      {children}
    </div>
  );
}

function InfoColumn({ title, children, ...props }) {
  return (
    <div {...props} className={css.infoColumn}>
      <div className={css.infoTitle}>{title}</div>
      <div className={css.infoChildren}>{children}</div>
    </div>
  );
}

function BottomRow({ children, className, ...props }) {
  return (
    <div {...props} id="bottomRow" className={`${css.bottomRow} ${className}`}>
      {children}
    </div>
  );
}

function SourceList({ sources, className, ...props }) {
  if (!sources) return null;

  return (
    <div {...props} className={`${css.sources} ${className}`}>
      {sources.map((src) => (
        <Source key={src} src={src} />
      ))}
    </div>
  );
}

export const BaseCard = {
  Root,
  Row,
  LeftColumn,
  RightColumn,
  DateRow,
  Title,
  InfoColumn,
  BottomRow,
  SourceList,
};
