import React from 'react';

import css from './DeleteNoteModal.module.css';
import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../Buttom/Buttom';

function DeleteNote({
  onClose,
  note,
  onDelete,
  isConnectionNote,
  isComplianceNote,
}) {
  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Delete Note</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      {note?.note && (
        <textarea
          className={css.textarea}
          type="text-area"
          disabled
          value={note.note}
        />
      )}

      {isComplianceNote && (
        <div className={css.message}>
          This will only remove the note attached to your compliance.
        </div>
      )}

      {isConnectionNote && (
        <div className={css.message}>
          This will only remove the note attached to your contact.
        </div>
      )}

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="danger" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </div>
  );
}

export default DeleteNote;
