import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { LogoAxis } from '@axis/xyz.logos.axis';
import Avatar from '../Avatar/Avatar';
import SolidFeed from '../../../icons/SolidFeed';
import SolidExplore from '../../../icons/SolidExplore';
import SavedSearchs from '../../../icons/SavedSearchs';
import Bookmark from '../../../icons/Bookmark';
import Connection from '../../../icons/Connection';
import Notes from '../../../icons/Notes';
import Compliant from '../../../icons/Compliant';
import { useAxisData } from '../../../contexts/AxisDataContext';
import { useIdentity } from '../../../contexts/IdentityContext';
import BoldSearch from '../../../icons/BoldSearch';
// import MagicBellComponent from '../../../components/MagicBell/MagicBell';

import css from './SideNav.module.css';
import useNavigation from '../../hooks/useNavigation';
import { triggerCommandKey } from '../CommandKey/CommandKey';

function SideNav() {
  const { user } = useIdentity();
  const { topics } = useAxisData();
  const { replaceParams, params } = useNavigation();

  const networkRoute = useMemo(() => {
    const activeTopic = topics.find((t) => t.id === user.primaryTopic) || {};
    const mainEntity = activeTopic?.mainEntityId;

    if (mainEntity) {
      return `/network`;
    }

    return `/network?&entityId=${user.id}`;
  }, [user, topics]);

  const toggleLeftPanel = (panel) => {
    const paramName = 'leftPanel';
    const leftPanelOpened = params.get(paramName);
    const isSamePanel = panel === leftPanelOpened;
    params.delete('isFilterOpen');

    if (isSamePanel) {
      params.delete(paramName, panel);
    } else {
      params.set(paramName, panel);
    }
    replaceParams(params);
  };

  const { pathname } = window.location;
  const isFeedActive = pathname.includes('/feed');
  const isNetworkActive = pathname.includes('/network');
  const isSavedSearchOpen = params.get('leftPanel') === 'saved-searches';
  const isBookmarkOpen = params.get('leftPanel') === 'bookmarks';
  const isNotesOpen = params.get('leftPanel') === 'notes';
  const isConnectionsOpen = params.get('leftPanel') === 'connections';
  const isCompliantOpen = params.get('leftPanel') === 'compliance';

  return (
    <div className={css.main}>
      <div className={css.list}>
        <Link to="/" className={css.link}>
          <LogoAxis className={css.logo} />
        </Link>

        <SideNavButton
          isDock
          onClick={triggerCommandKey}
          icon={<BoldSearch />}
          data-cy="trigger-search"
        />

        <div className={css.divider} />

        <SideNavButton
          routerLink="/feed"
          icon={<SolidFeed />}
          title="Feed"
          isActive={isFeedActive}
        />

        <SideNavButton
          routerLink={networkRoute}
          icon={<SolidExplore />}
          title="Network"
          isActive={isNetworkActive}
        />

        <div className={css.list} style={{ marginTop: 100 }}>
          <SideNavButton
            isDock
            title="Searches"
            icon={<SavedSearchs />}
            isActive={isSavedSearchOpen}
            data-cy="toggle-saved-searches"
            onClick={() => toggleLeftPanel('saved-searches')}
          />

          <SideNavButton
            isDock
            title="Bookmarks"
            icon={<Bookmark />}
            isActive={isBookmarkOpen}
            data-cy="toggle-bookmarks"
            onClick={() => toggleLeftPanel('bookmarks')}
          />

          <SideNavButton
            isDock
            title="Notes"
            icon={<Notes />}
            isActive={isNotesOpen}
            data-cy="toggle-notes"
            onClick={() => toggleLeftPanel('notes')}
          />

          <SideNavButton
            isDock
            title="Contacts"
            icon={<Connection />}
            isActive={isConnectionsOpen}
            data-cy="toggle-connections"
            onClick={() => toggleLeftPanel('connections')}
          />

          <SideNavButton
            isDock
            title="Compliance"
            icon={<Compliant />}
            isActive={isCompliantOpen}
            data-cy="toggle-compliance"
            onClick={() => toggleLeftPanel('compliance')}
          />
        </div>
      </div>

      <div className={css.list}>
        {/* <div className={css.bell}>
          <MagicBellComponent user={user} />
        </div> */}
        <Avatar user={user} />
      </div>
    </div>
  );
}

export default SideNav;

function SideNavButton({
  routerLink = null,
  icon,
  title,
  isActive,
  onClick,
  isDock,
}) {
  const Wrapper = routerLink ? Link : 'div';

  return (
    <Wrapper
      to={routerLink}
      className={css.wrapper}
      data-active={isActive}
      data-dock={isDock}
      onClick={onClick}
    >
      <div className={css.sideButtonIcon}>{icon}</div>
      <h3>{title}</h3>
    </Wrapper>
  );
}
