import React, { useState } from 'react';

import Modal from 'react-modal';
import css from './CompliantRegulations.module.css';
import PanelHeader from '../PanelHeader/PanelHeader';
import CompliantCard from './CompliantCard/CompliantCard';
import useCompliRegulations from '../../../hooks/useCompliantRegulations';
import { modalStyles } from '../../../shared/modal';
import EditCompliantRegulation from './EditCompliantRegulation/EditCompliantRegulation';
import notify from '../../../../lib/notify';

const typeModal = {
  EDIT: 'EDIT',
};

function CompliantRegulations() {
  const [openedModal, setOpenedModal] = useState(null);
  const { data, loading, refetch, editCompliantRegulation } =
    useCompliRegulations();
  const regulations = data?.searchEventsAndRegulations?.regulations?.data || [];

  const onSearch = (query) => {
    refetch({
      name: query,
    });
  };

  const onEdit = (regulation) => {
    setOpenedModal({ modal: typeModal.EDIT, context: regulation });
  };

  const onConfirmEditRegulation = async ({ regulationId, flag, note }) => {
    setOpenedModal(null);
    try {
      await editCompliantRegulation({
        variables: {
          regulationId,
          flag,
          note,
        },
      });
      refetch();
      notify.success('Regulation updated!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  return (
    <div className={css.main}>
      <PanelHeader
        count={regulations.length}
        onSearch={onSearch}
        title="Compliant regulations"
      />
      {loading && <LoadingState />}

      {!loading && (
        <div className={css.list}>
          {regulations.map((regulation) => (
            <CompliantCard
              key={regulation.di}
              regulation={regulation}
              note={regulation.complianceNote}
              onEdit={onEdit}
            />
          ))}
        </div>
      )}

      <Modal
        isOpen={Boolean(openedModal)}
        style={modalStyles}
        contentLabel="Update Compliant Regulation"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpenedModal(null)}
      >
        {openedModal?.modal === typeModal.EDIT && (
          <EditCompliantRegulation
            regulationId={openedModal.context.id}
            isCompliant={openedModal.context.isCompliant}
            note={openedModal.context.complianceNote}
            onClose={() => setOpenedModal(null)}
            onConfirmChanges={onConfirmEditRegulation}
          />
        )}
      </Modal>
    </div>
  );
}

const LoadingState = () => (
  <div className={css.loadingState}>
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
  </div>
);

export default CompliantRegulations;
