import React, { useState } from 'react';
import { formatDate } from '../../../../../lib/date';
import EventCard from '../../../../../components/EventCard/EventCard';
import { sendEvent } from '../../../../../contexts/AnalyticsTrackingContext';
import { useRouteInformation } from '../../../../../contexts/RouteInformationContext';

const ResultEventCard = ({ facet }) => {
  const router = useRouteInformation();

  const {
    id,
    title,
    date,
    entities,
    countries,
    briefs,
    landscapes,
    sources = [],
    notesCount,
    summary,
    bullets,
    magnitude,
    sentiment,
    quotes,
    language,
  } = facet;

  const [localNotesCount, setLocalNotesCount] = useState(notesCount);

  const handleEntityClick = (entityId) => {
    sendEvent('advanced_search_facet_click', {
      description: 'Click on a event result from the search page',
      type: 'event',
      entityId,
    });

    router.toggleDossier({ dossierEntityId: entityId, dossierType: 'entity' });
  };

  return (
    <EventCard
      id={id}
      title={title}
      magnitude={magnitude}
      sentiment={sentiment}
      summary={summary}
      bullets={bullets}
      pillSize="medium"
      date={formatDate(date)}
      entities={entities}
      briefs={briefs}
      countries={countries}
      landscapes={landscapes}
      onEntityClick={handleEntityClick}
      sources={sources}
      notesCount={localNotesCount}
      handleReloadDossier={() => setLocalNotesCount(localNotesCount + 1)}
      quotes={quotes}
      language={language}
    />
  );
};

export default ResultEventCard;
