import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useReducer,
} from 'react';
import { useApolloClient } from '../../hooks/useApolloClient';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import notify from '../../lib/notify';
import { getResultType, mergeFacetSearchResults } from '../../lib/search';
import {
  fetchAiSuggestions,
  fetchSearchEntityResults,
  fetchSearchFacetResults,
} from './api';

import SearchBar from './components/SearchBar/SearchBar';
import SearchResults from './components/SearchResults/SearchResults';

import css from './Search.module.css';
import SearchHeader from './components/SearchHeader/SearchHeader';
import useSearchCounts from '../../hooks/useSearchCounts';
import useNavigation from '../../v2/hooks/useNavigation';
import ToggleSideNav from '../Feed/components/ToggleSidenav/ToggleSidenav';
import { useSharedState } from '../../contexts/SharedStateContext';
import Filter from '../../v2/components/Filter/Filter';
import {
  ACTIONS,
  filterDefaultState,
  filterReducer,
} from '../../v2/components/Filter/filterReducer';
import {
  getIdList,
  getSelectedValues,
  getSelectedValuesOnTree,
  getValueList,
  normalizeKey,
  normalizeSearchActiveFilter,
  searchCountToFilterAdapter,
} from '../../v2/components/Filter';
import { getEventAndRegulationFilterCategories, isFilterEqual } from './helper';

const SearchContext = createContext();

const getFiltersSavedOnSessionStorageByQueryId = (queryId, type) => {
  if (!queryId) return [];
  const savedFilters = sessionStorage.getItem(queryId);
  try {
    const parsed = JSON.parse(savedFilters);
    return parsed[type] ?? [];
  } catch (_) {
    return [];
  }
};

const Search = () => {
  const client = useApolloClient();
  const router = useRouteInformation();
  const { params, replaceParams } = useNavigation();
  const { counts, fetchSearchCount, isCountLoading } = useSearchCounts();
  const [filterState, dispatch] = useReducer(filterReducer, filterDefaultState);

  const [activeSearchText, setActiveSearchText] = useState('');
  const [pagination, setPagination] = useState({ skip: 0, limit: 10 });
  const [isFacetResultsLoading, setFacetResultsLoading] = useState(false);
  const [facetResults, setFacetResults] = useState([]);
  const [isEntityResultsLoading, setEntityResultsLoading] = useState(false);
  const [entityResults, setEntityResults] = useState([]);
  const [aiEntitySuggestions, setAiEntitySuggestions] = useState([]);

  const [savedSearches, setSavedSearches] = useState([]);
  const [savedSearchId, setSavedSearchId] = useState(null);
  const [homeFeedSavedSearch, setHomeFeedSavedSearch] = useState(null);
  const [savedSearchName, setSavedSearchName] = useState(null);
  const { selectedSavedSearch, setSelectedSavedSearch } = useSharedState();

  const dossierType = router.query.get('dossierType');
  const searchQueryId = router.query.get('queryId');
  const isFilterOpen = params.get('isFilterOpen');
  const searchQuery = router.query.get('search');

  const searchTypes = filterState.previousState?.activeFilter
    ? [normalizeSearchActiveFilter(filterState.previousState?.activeFilter)]
    : [];

  const visibleResultType = getResultType(
    normalizeSearchActiveFilter(filterState.previousState?.activeFilter),
  );

  const fetchFacetResults = async ({
    search = '',
    type,
    countries,
    industries,
    entities,
    pageInfo,
    dates,
    regulationTypes,
  }) => {
    if (type === 'actors') return;

    setFacetResultsLoading(true);

    const variables = {
      name: search,
      filterIndustries: industries,
      filterEntities: entities,
      topics: countries,
      entities,
      filterCategories: getEventAndRegulationFilterCategories(
        type,
        pageInfo,
        regulationTypes,
      ),
    };

    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      variables.startDate = startDate;
      variables.endDate = endDate;
    }

    try {
      const { data, errors } = await fetchSearchFacetResults({
        client,
        ...variables,
      });

      const results = data.searchEventsAndRegulations;
      if (!results && errors) {
        throw new Error();
      }

      setFacetResults((currentResults) => {
        return mergeFacetSearchResults({
          currentResults,
          newResults: results,
          facetType: type,
        });
      });
    } catch (error) {
      notify.error('There was an error retrieving events & regulations.');
    } finally {
      setFacetResultsLoading(false);
    }
  };

  const fetchEntityResults = async ({
    search,
    type,
    countries,
    entities,
    industries,
    pageInfo,
    actorTypes,
  }) => {
    if (!['all', 'actors'].includes(type)) return;

    setEntityResultsLoading(true);

    const variables = {
      name: search,
      topics: countries,
      filterEntities: entities,
      filterIndustries: industries,
      filterActorTypes: actorTypes.length === 8 ? [] : actorTypes,
      ...pageInfo,
    };

    const shouldRunAiSearch =
      variables.filterEntities.length === 0 &&
      variables.filterIndustries.length === 0 &&
      variables.topics.length === 0 &&
      search;

    const promises = [
      fetchSearchEntityResults({
        client,
        ...variables,
      }),
    ];

    if (shouldRunAiSearch) {
      promises.push(fetchAiSuggestions({ client, term: search }));
    }

    try {
      const [searchEntities, aiSuggestions] = await Promise.allSettled(
        promises,
      );

      setAiEntitySuggestions(aiSuggestions?.value);

      setEntityResults((currentResults) => {
        // just use new values if it's a new page
        if (pageInfo.skip === 0) {
          return searchEntities.value;
        }

        return [...currentResults, ...searchEntities.value];
      });
    } catch (error) {
      notify.error('There was an error retrieving the search results.');
    } finally {
      setEntityResultsLoading(false);
    }
  };

  const resetPagination = () => {
    setPagination({ skip: 0, limit: 10 });
    setEntityResults([]);
    setAiEntitySuggestions([]);
    setFacetResults([]);
  };

  const applyFilters = (filters, search, resetPageInfo = true) => {
    const pageInfo = resetPageInfo ? { skip: 0, limit: 10 } : pagination;

    if (resetPageInfo) {
      resetPagination();
    }

    const isSameFilter = isFilterEqual(filters.previousState, {
      ...filters,
      search,
    });

    if (!isSameFilter) {
      fetchSearchCount({
        search,
        industries: getIdList(filters.industries),
        countries: getIdList(filters.countries),
        entities: getIdList(filters.entities),
        dates: filters.dates,
        client,
      });
    }

    fetchFacetResults({
      search,
      type: normalizeSearchActiveFilter(filters.activeFilter),
      pageInfo,
      industries: getIdList(filters.industries),
      countries: getIdList(filters.countries),
      entities: getIdList(filters.entities),
      dates: filters.dates,
      regulationTypes: getValueList(getSelectedValues(filters.regulationTypes)),
    });

    fetchEntityResults({
      countries: getIdList(filters.countries),
      entities: getIdList(filters.entities),
      industries: getIdList(filters.industries),
      pageInfo,
      search,
      type: normalizeSearchActiveFilter(filters.activeFilter),
      actorTypes: getSelectedValuesOnTree(filters.actorTypes),
    });

    dispatch({ type: ACTIONS.UPDATE_SEARCH_TEXT, payload: search });
    dispatch({ type: ACTIONS.APPLY_CHANGES });
  };

  useEffect(() => {
    if (pagination.skip > 0) {
      applyFilters(filterState, activeSearchText, false);
    }
  }, [pagination]);

  const updateSearchType = (type) => {
    const newActiveFilter = normalizeKey(type);
    const filters = { ...filterState, activeFilter: newActiveFilter };
    dispatch({ type: ACTIONS.UPDATE_ACTIVE_FILTER, payload: newActiveFilter });
    applyFilters(filters, activeSearchText);
  };

  const onSearch = (text) => {
    setActiveSearchText(text);
    applyFilters(filterState, text);
  };

  const onToggleFilterClicked = () => {
    params.delete('leftPanel');
    params.set('isFilterOpen', true);
    replaceParams(params);
  };

  const filterCounts = useMemo(() => {
    return searchCountToFilterAdapter(counts);
  }, [counts]);

  const getActiveFilterFromSearchId = () => {
    const activeFilter = getFiltersSavedOnSessionStorageByQueryId(
      searchQueryId,
      'searchTypes',
    );

    if (activeFilter instanceof Array) {
      return 'all';
    }

    return activeFilter;
  };

  const getInitialFilters = (queryId) => {
    if (!queryId) return filterState;

    return {
      ...filterState,
      dates: getFiltersSavedOnSessionStorageByQueryId(searchQueryId, 'dates'),
      countries: getFiltersSavedOnSessionStorageByQueryId(
        searchQueryId,
        'countries',
      ),
      industries: getFiltersSavedOnSessionStorageByQueryId(
        searchQueryId,
        'industries',
      ),
      entities: getFiltersSavedOnSessionStorageByQueryId(
        searchQueryId,
        'entities',
      ),
      activeFilter: getActiveFilterFromSearchId(),
    };
  };

  const applySavedSearchFilter = (savedSearch) => {
    setSavedSearchId(savedSearch.id);
    setSavedSearchName(savedSearch.name);

    const searchData = JSON.parse(savedSearch.search_data);

    setHomeFeedSavedSearch(searchData.isHomeFeed ? savedSearch : null);
    setActiveSearchText(searchData.name);

    const newFilters = {
      ...filterState,
      dates: searchData.searchDates,
      industries: searchData.filterIndustries,
      countries: searchData.topics,
      entities: searchData.filterEntities,
      activeFilter: normalizeKey(searchData.filterCategories[0] ?? 'all'),
    };

    dispatch({ type: ACTIONS.UPDATE_ANY, payload: newFilters });
    applyFilters(newFilters, searchData.name);
  };

  const clearAll = () => {
    setActiveSearchText('');
    applyFilters(filterDefaultState, '', true);
  };

  useEffect(() => {
    if (filterState.runSearch) {
      applyFilters(filterState, activeSearchText, true);
    }
  }, [filterState.runSearch]);

  // Apply saved search when user clicks on leftPanel
  useEffect(() => {
    if (!selectedSavedSearch) return;

    applySavedSearchFilter(selectedSavedSearch);
  }, [selectedSavedSearch]);

  // Remove saved search when unMounting component
  useEffect(() => () => setSelectedSavedSearch(null), []);

  useEffect(() => {
    if (searchQuery) return;
    const filters = getInitialFilters(searchQueryId);

    if (searchQueryId) {
      dispatch({
        type: ACTIONS.UPDATE_ANY,
        payload: filters,
      });

      applyFilters(filters, activeSearchText);
    }

    fetchSearchCount({
      search: activeSearchText,
      industries: getIdList(filters.industries),
      countries: getIdList(filters.countries),
      entities: getIdList(filters.entities),
      dates: filters.dates,
      client,
    });
  }, []);

  return (
    <SearchContext.Provider
      value={{
        visibleResultType,
        searchIndustries: filterState.industries,
        searchEntities: filterState.entities,
        searchCountries: filterState.countries,
        searchDates: filterState.dates,
        entityResults,
        facetResults,
        searchTypes,
        homeFeedSavedSearch,
        previousState: filterState.previousState,
        isFacetResultsLoading,
        isEntityResultsLoading,
        aiEntitySuggestions,
        savedSearchId,
        savedSearchName,
        counts,
        activeSearchText,
        savedSearches,
        pagination,
        isCountLoading,

        clearAll,
        updateSearchType,
        dispatch,
        onSearch,
        setSavedSearches,
        setHomeFeedSavedSearch,
        setPagination,
      }}
    >
      <div data-cy="search" className={css.main}>
        <Filter
          dispatch={dispatch}
          dataCy="search-filters"
          showActorsOption
          showDateFilter
          filterState={filterState}
          isFilterOpen={isFilterOpen}
          onFilterApplied={(data) => applyFilters(data, activeSearchText, true)}
          counts={filterCounts}
          isLoadingCounts={isCountLoading}
        />
        <aside className={css.toggleFilter}>
          <ToggleSideNav
            filterStatus={isFilterOpen ? 'expanded' : 'closed'}
            onClick={onToggleFilterClicked}
          />
        </aside>
        <div
          data-dossier={dossierType ? 'open' : 'closed'}
          className={css.content}
        >
          <div className={css.header}>
            <SearchBar />
            <SearchHeader />
          </div>
          <div className={css.results}>
            <SearchResults />
          </div>
        </div>
      </div>
    </SearchContext.Provider>
  );
};

export default Search;

export const useSearch = () => useContext(SearchContext);
