// const colorRangeMap = {
//   '-10': '#D82953',
//   '-9': '#D82953',
//   '-8': '#E6466C',
//   '-7': '#E6466C',
//   '-6': '#B65C6D',
//   '-5': '#B65C6D',
//   '-4': '#996769',
//   '-3': '#996769',
//   '-2': '#A18D86',
//   '-1': '#A18D86',
//   0: '#B9C0D4',
//   1: '#849789',
//   2: '#849789',
//   3: '#517A63',
//   4: '#517A63',
//   5: '#428F6F',
//   6: '#428F6F',
//   7: '#22A26A',
//   8: '#22A26A',
//   9: '#10AE6C',
//   10: '#10AE6C',
// };

const colorRangeMap = {
  '-10': '#D82953',
  '-9': '#D82953',
  '-8': '#D82953',
  '-7': '#D82953',
  '-6': '#D82953',

  '-5': '#B65C6D',
  '-4': '#B65C6D',
  '-3': '#B65C6D',
  '-2': '#B65C6D',
  '-1': '#B65C6D',

  0: '#B9C0D4',

  1: '#428F6F',
  2: '#428F6F',
  3: '#428F6F',
  4: '#428F6F',
  5: '#428F6F',

  6: '#10AE6C',
  7: '#10AE6C',
  8: '#10AE6C',
  9: '#10AE6C',
  10: '#10AE6C',
};

const nomalizeScore = (nodeScore) => {
  return Math.abs(nodeScore);
};

export { colorRangeMap, nomalizeScore };
