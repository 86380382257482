import React, { useEffect, useState } from 'react';

import css from './SearchInput.module.css';
import SelectIcon from '../../../../icons/Select';

const DEBOUNCE_TIME = 500;

function SearchInput({
  isLoading,
  onQueryChange,
  query,
  clearAll,
  count,
  dataCy,
  placeholder,
  ...props
}) {
  const [value, setValue] = useState(query);

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };

  const clearInput = (event) => {
    event.stopPropagation();
    setValue('');
    clearAll();
  };

  /* Emit event after 500 ms to filter tree by query */
  useEffect(() => {
    const timer = setTimeout(() => {
      onQueryChange(value);
    }, DEBOUNCE_TIME);

    return () => clearTimeout(timer);
  }, [value]);

  /* Change internal value when query changes */
  useEffect(() => {
    if (value !== query) {
      setValue(query);
    }
  }, [query]);

  const isCleanable = typeof clearAll === 'function' && !isLoading;

  return (
    <div
      className={css.main}
      data-loading={isLoading}
      data-cy={dataCy}
      {...props}
    >
      <div>
        <SelectIcon className={css.leftIcon} />
        <input
          type="text"
          value={value}
          className={css.input}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
      </div>

      <div>
        {count > 0 && <div className={css.count}>{count}</div>}

        {isCleanable && (
          <button
            type="button"
            className={css.clearButton}
            onClick={clearInput}
          >
            Clear
          </button>
        )}
      </div>
    </div>
  );
}

export default SearchInput;
