import React from 'react';

import css from './LeftPanel.module.css';
import useNavigation from '../../hooks/useNavigation';
import SavedSearches from './SavedSearches/SavedSearches';
import CompliantRegulations from './CompliantRegulations/CompliantRegulations';
import Connections from './Connections/Connections';
import Bookmarks from './Bookmarks/Bookmarks';
import Notes from './Notes/Notes';

const panelComponentMap = {
  'saved-searches': <SavedSearches />,
  compliance: <CompliantRegulations />,
  connections: <Connections />,
  bookmarks: <Bookmarks />,
  notes: <Notes />,
};

function LeftPanel() {
  const { params } = useNavigation();
  const panel = params.get('leftPanel');

  const Component = panelComponentMap[panel];

  return (
    <div className={css.main} data-open={Boolean(panel)}>
      {Component}
    </div>
  );
}

export default LeftPanel;
