import React from 'react';

const SolidFeed = () => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7V4Z"
      fill="#0E0F10"
    />
    <path
      d="M3 17C3 16.4477 3.44772 16 4 16H20C20.5523 16 21 16.4477 21 17V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V17Z"
      fill="#0E0F10"
    />
    <path
      d="M21 10.5C21 9.94772 20.5523 9.5 20 9.5H4C3.44772 9.5 3 9.94772 3 10.5V13.5C3 14.0523 3.44772 14.5 4 14.5H20C20.5523 14.5 21 14.0523 21 13.5V10.5Z"
      fill="#0E0F10"
    />
  </svg>
);

export default SolidFeed;
