import React, { useState } from 'react';

import css from './EditNoteModal.module.css';
import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../Buttom/Buttom';

function EditNoteModal({ note = '', onClose, onConfirmChanges }) {
  const [textAreaValue, setTextAreaValue] = useState(note);

  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Edit Note</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      <textarea
        className={css.textarea}
        type="text-area"
        placeholder="Edit your note"
        onChange={(e) => setTextAreaValue(e.target.value)}
        value={textAreaValue}
      />

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onConfirmChanges(textAreaValue);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default EditNoteModal;
