import React from 'react';

import css from './LinkCard.module.css';
import Source from '../../../components/Source/Source';

function LinkCard({ title, src, onClick }) {
  const resource = src?.startsWith('http') ? src : `https://${src}`;

  return (
    <div className={css.main} onClick={onClick}>
      <h2>{title}</h2>
      <a
        className={css.source}
        rel="noreferrer"
        target="_blank"
        href={resource}
      >
        <Source source={src}/>
      </a>
    </div>
  );
}

export default LinkCard;
