import React from 'react';

import css from './Source.module.css';
import SourceIcon from '../../../icons/SourceIcon/SourceIcon';
import { getHost } from '../../../lib/facets/attachment';

function Source({ src }) {
  const resource = src?.startsWith('http') ? src : `https://${src}`;

  return (
    <a className={css.source} rel="noreferrer" target="_blank" href={resource}>
      <SourceIcon />
      {getHost(src)}
    </a>
  );
}

export default Source;
