import React, { useState } from 'react';
import { useRouteInformation } from '../contexts/RouteInformationContext';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import { useIdentity } from '../contexts/IdentityContext';
import { useAxisData } from '../contexts/AxisDataContext';

const ProtectAuthorized = ({ children }) => {
  const { token, user } = useIdentity();
  const { topics } = useAxisData();
  const router = useRouteInformation();

  const [loadingScreen, setLoadingScreen] = useState(false);

  if (!token || !user.id) {
    router.navigateToLogin(router.location);
    return null;
  }

  if (loadingScreen || !user.id || !topics.length)
    return <LoadingScreen setLoading={setLoadingScreen} loading={!user.id} />;

  return children;
};

export default ProtectAuthorized;
