export const getHost = (url) => {
  try {
    const { hostname, pathname } = new URL(url);
    if (!hostname) return '';
    // Known sources we want to improve
    if (hostname === 'www.linkedin.com') {
      return 'LinkedIn';
    }
    if (hostname.startsWith('www.')) {
      return hostname.slice(4);
    }
    /*
     * Our S3 buckets show up like "assets.axis.xyz" - which we don't want
     * Instead we replace it to show "Source"
     */
    if (hostname === 'assets.axis.xyz') {
      return 'Document 📎';
    }
    /*
     * Although sometimes they won't be uploaded properly and will instead
     * be /axis.assets/
     */
    if (pathname.startsWith('/axis.assets/')) {
      return 'Document 📎';
    }
    return hostname;
  } catch (e) {
    return url;
  }
};
