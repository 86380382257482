import { useMutation, useQuery } from '@apollo/client';

import TOGGLE_USER_COMPLIANCE from '../../queries/toggleUserCompliance';
import SEARCH_COMPLIANT_REGULATIONS from '../../queries/searchCompliantRegulations';

function useCompliantRegulations() {
  const [editCompliantRegulation] = useMutation(TOGGLE_USER_COMPLIANCE);
  const { data, loading, refetch } = useQuery(SEARCH_COMPLIANT_REGULATIONS, {
    variables: {
      compliance: true,
      filterCategories: {
        regulations: { skip: 0, limit: 25 },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return { data, loading, refetch, editCompliantRegulation };
}

export default useCompliantRegulations;
