import React, { useEffect, useState } from 'react';
import Source from '../Source/Source';
import EntityPill from '../EntityPill/EntityPill';
import BriefPill from '../BriefPill/BriefPill';
import NoteButton from '../Note/NoteButton';
import { CountIndicator } from '../CountIndicator/CountIndicator';
import { FieldRow } from '../FIeldRow/FIeldRow';
import Pagination from '../../icons/Pagination';
import css from './RegulationCard.module.css';
import { useModal } from '../../contexts/ModalContext';
import ComplianceButton from '../ComplianceButton/ComplianceButton';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import { filterIndustries } from '../../utils';
import useNavigation from '../../v2/hooks/useNavigation';

const RegulationCard = ({
  id,
  legalName,
  summarizedName,
  visibleSummary,
  sources,
  jurisdiction,
  isExpanded,
  date,
  bullets = [],
  notesCount = 0,
  isCompliant,
  issuer = {},
  authorizedIssuer,
  industries = [],
  handleEntityClick,
  setExpanded,
  dataLocation,
  regulationType,
}) => {
  const { setModalData, resetModalData } = useModal();
  const [localNotesCount, setLocalNotesCount] = useState(notesCount);
  const [localCompliant, setLocalCompliant] = useState(isCompliant);
  const router = useRouteInformation();
  const { toggleRegulationDossier } = useNavigation();

  useEffect(() => {
    setLocalNotesCount(notesCount);
  }, [notesCount]);

  useEffect(() => {
    setLocalCompliant(isCompliant);
  }, [isCompliant]);

  const onAddNoteClick = () => {
    const regulation = {
      id,
      legalName,
      summarizedName,
      jurisdiction,
      date,
      issuer,
      industries,
      dataLocation,
      dossierEntityId: router.query.get('entityId'),
    };
    router.openDossier({ regulation, dossierType: 'regulation' });
  };

  const onComplianceClick = () => {
    setModalData({
      modalType: 'COMPLIANCE',
      openModal: true,
      regulationId: id,
      isCompliant: localCompliant,
      handleModalClose: ({ flag }) => {
        resetModalData();
        setLocalCompliant(flag);
      },
    });
  };

  const onSummarizedNameClick = () => {
    toggleRegulationDossier(id);
  };

  const filteredIndustries = filterIndustries(industries);
  const isLegalNameValid = legalName !== 'na' && legalName !== '';

  return (
    <div
      className={css.card}
      data-cy="search-result-facet"
      data-cy-facet="regulation"
    >
      <div className={css.content}>
        <div className={css.title} onClick={onSummarizedNameClick}>
          {summarizedName}
        </div>

        {regulationType && <FieldRow label="Type" text={regulationType} />}

        {issuer && issuer.id && (
          <FieldRow label="Issued By">
            <EntityPill
              size="small"
              type={issuer.type}
              image={issuer.image}
              name={issuer.name}
              shortName={issuer.shortestName}
              inSubscription={issuer.inSubscription}
              isConnected={issuer.isConnected}
              tooltip={issuer.tooltip}
              onClick={() => handleEntityClick(issuer.id)}
            />
          </FieldRow>
        )}

        {authorizedIssuer && authorizedIssuer.id && (
          <FieldRow label="Relevant Individual">
            <EntityPill
              size="small"
              type={authorizedIssuer.type}
              image={authorizedIssuer.image}
              name={authorizedIssuer.name}
              shortName={authorizedIssuer.shortestName}
              tooltip={authorizedIssuer.tooltip}
              inSubscription={authorizedIssuer.inSubscription}
              onClick={() => handleEntityClick(authorizedIssuer.id)}
            />
          </FieldRow>
        )}

        {filteredIndustries && filteredIndustries.length > 0 && (
          <FieldRow
            label={filteredIndustries.length === 1 ? 'Industry' : 'Industries'}
          >
            <div className={css.tags}>
              {filteredIndustries.map((regulationLink) => (
                <BriefPill
                  key={regulationLink.id}
                  name={regulationLink.name}
                  tooltip={regulationLink.tooltip}
                />
              ))}
            </div>
          </FieldRow>
        )}
        {jurisdiction && (
          <FieldRow label="Jurisdiction">
            <BriefPill name={jurisdiction} isGeography />
          </FieldRow>
        )}
        {isLegalNameValid && <FieldRow label="Legal Name" text={legalName} />}
        {(visibleSummary || (bullets && bullets.length > 0)) && (
          <FieldRow label="Summary">
            {visibleSummary && (
              <p className={css.textValue}>{visibleSummary}</p>
            )}
            {isExpanded && bullets && bullets.length > 0 ? (
              <ul className={css.bullets}>
                {bullets &&
                  bullets.length &&
                  bullets.map((bullet) => (
                    <li className={css.textValue} key={bullet}>
                      {bullet.replaceAll("'", '')}
                    </li>
                  ))}
              </ul>
            ) : null}
            <button
              type="button"
              className={css.showToggle}
              onClick={() => setExpanded((e) => !e)}
            >
              See {isExpanded ? 'less' : 'more'}
              <Pagination
                className={css.showToggleIcon}
                direction={isExpanded ? 'up' : 'down'}
              />
            </button>
          </FieldRow>
        )}
        {/* {isRegulationDateValid(date) && (
          <FieldRow label="Issue Date" text={formatDate(date)} />
        )} */}
      </div>

      <div className={css.footer}>
        {sources && sources.length > 0 && (
          <p data-cy="source-list" className={css.source}>
            {sources.map((regSource, index) => (
              <Source key={`${regSource}:${index}`} source={regSource} />
            ))}
          </p>
        )}
        <div className={css.actionButtons}>
          <NoteButton
            data-status={localNotesCount ? 'active' : 'inactive'}
            className={css.note}
            notesAvailable={localNotesCount}
            onClick={onAddNoteClick}
            trailing={
              localNotesCount ? (
                <CountIndicator count={localNotesCount} />
              ) : null
            }
          />
          <ComplianceButton
            className={css.compliance}
            isCompliant={localCompliant}
            onClick={onComplianceClick}
          />
        </div>
      </div>
    </div>
  );
};

export default RegulationCard;
