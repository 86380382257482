import React, { useEffect, useMemo, useReducer, useRef } from 'react';

import css from './IndustryFilter.module.css';
import FilterButton from '../components/FilterButton/FilterButton';
import FilterPopUp from '../components/FilterPopUp/FilterPopUp';
import { PopUpCard } from '../components/PopUpCard/PopUpCard';
import { reducer, industryFilterState, ACTIONS } from './reducer';
import MultiSelectionTree from '../../../../components/MultiSelectionTree/MultiSelectionTree';
import { filterInTree } from '../../../../utils';
import ListDivider from '../components/ListDivider/ListDivider';

function IndustryFilter({
  activeIndustryFilters,
  options,
  applyFilters,
  customClass,
}) {
  const [state, dispatch] = useReducer(reducer, {
    ...industryFilterState,
    options,
  });

  const buttonRef = useRef();

  /** Create a flat array from original options tree, it's a faster way to iterate over the tree */
  const flatenOptions = useMemo(() => {
    const sectors = options;
    const subSectors = sectors.flatMap((option) => option.children);
    const industries = subSectors.flatMap((option) => option.children);
    return [sectors, subSectors, industries].flat();
  }, [options]);

  const onClose = () => {
    dispatch({ type: ACTIONS.CLOSE_POPUP });
  };

  const handleButtonClick = () => {
    dispatch({
      type: ACTIONS.OPEN_POPUP,
      payload: {
        selectedOptions: activeIndustryFilters,
        options,
      },
    });
  };

  const onCancel = () => {
    onClose();
  };

  const onSelectAll = () => {
    dispatch({ type: ACTIONS.SELECT_ALL });
  };

  const onDeselectAll = () => {
    dispatch({ type: ACTIONS.DESELECT_ALL });
  };

  const onApply = () => {
    dispatch({ type: ACTIONS.CLOSE_POPUP });
    applyFilters(
      flatenOptions.filter((option) =>
        state.selectedOptions.includes(option.id),
      ),
    );
  };

  const onInputChange = (event) => {
    dispatch({ type: ACTIONS.UPDATE_QUERY, payload: event.target.value });
  };

  const filteredOptions = useMemo(
    () => filterInTree(state.options, state.query),
    [state.options, state.query],
  );

  /** Sync active filters with tree view  */
  useEffect(() => {
    dispatch({
      type: ACTIONS.SYNC_SELECTED_OPTIONS,
      payload: activeIndustryFilters,
    });
  }, [activeIndustryFilters]);

  return (
    <div
      className={`${css.main} ${customClass}`}
      data-cy="industry-filter"
      ref={buttonRef}
    >
      <FilterPopUp
        parentRef={buttonRef}
        isPopoverOpen={state.isPopupOpen}
        content={
          <PopUpCard.Root>
            <PopUpCard.Header
              title="Industries"
              isPopupOpen={state.isPopupOpen}
              count={state.selectedOptions.length}
              inputValue={state.query}
              onInputChange={onInputChange}
            />
            <PopUpCard.Body>
              <div className={css.list}>
                {state.selectedOptionsTree.length > 0 && (
                  <>
                    <ListDivider
                      title="SELECTED INDUSTRIES"
                      buttonLabel="Deselect All"
                      onClick={onDeselectAll}
                    />
                    <MultiSelectionTree
                      dispatch={dispatch}
                      options={state.selectedOptionsTree}
                      expandAll={Boolean(state.query)}
                      selectedOptions={state.selectedOptions}
                      expandedOptions={state.expandedOptions}
                    />
                  </>
                )}

                <ListDivider
                  title="ALL INDUSTRIES"
                  buttonLabel="Select All"
                  onClick={onSelectAll}
                />
                <MultiSelectionTree
                  dispatch={dispatch}
                  options={filteredOptions}
                  expandAll={Boolean(state.query)}
                  selectedOptions={state.selectedOptions}
                  expandedOptions={state.expandedOptions}
                />
              </div>
            </PopUpCard.Body>
            <PopUpCard.Footer onApply={onApply} onCancel={onCancel} />
          </PopUpCard.Root>
        }
        popupDimenssions={[380, 640]}
        onClickOutside={onClose}
      >
        <FilterButton
          name="Industries"
          count={activeIndustryFilters.length}
          onClick={handleButtonClick}
        />
      </FilterPopUp>
    </div>
  );
}

export default IndustryFilter;
