import React, { memo, useRef } from 'react';

import css from './Question.module.css';
import useSearch from '../../../../hooks/useSearch';
import Process from '../Process/Process';
import SmartResponse from '../SmartResponse/SmartResponse';
import Sources from '../Sources/Sources';

function Question({ question }) {
  const containerRef = useRef(null);
  const { isLoading, data } = useSearch(question);

  return (
    <div ref={containerRef} className={css.main}>
      <h1 className={css.question} data-cy="semantic-search-question">
        {question}
      </h1>
      <Process question={question} isQuestionLoading={isLoading} />
      <SmartResponse response={data.answer} />

      {data.answerCompleted && (
        <Sources sources={data.source} isLoading={!data.source} />
      )}
    </div>
  );
}

export default memo(Question);
