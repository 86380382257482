import React, { useState } from 'react';
import { trimText } from '../../../../../lib/text';
import { useRouteInformation } from '../../../../../contexts/RouteInformationContext';
import { sendEvent } from '../../../../../contexts/AnalyticsTrackingContext';
import { formatRegulationSummary } from '../../../../../lib/extractBullets';
import RegulationCard from '../../../../../components/RegulationCard/RegulationCard';

const ResultRegulationCard = ({
  id,
  summarizedName,
  issuer,
  authorizedIssuer,
  industries,
  jurisdiction,
  legalName,
  summary,
  bullets,
  createdAt,
  date,
  source,
  attachment,
  notesCount,
  isCompliant,
  regulationType,
}) => {
  const router = useRouteInformation();

  const [isExpanded, setExpanded] = useState(false);

  const clearSummary = formatRegulationSummary(summary);
  const visibleSummary = isExpanded ? clearSummary : trimText(summary, 150);

  const sources = [source, attachment].filter(Boolean);

  const handleEntityClick = (entityId) => {
    sendEvent('advanced_search_facet_click', {
      description: 'Click on a regulation result from the search page',
      type: 'regulation',
      entityId,
    });

    router.toggleDossier({ dossierEntityId: entityId, dossierType: 'entity' });
  };

  return (
    <RegulationCard
      id={id}
      bullets={bullets}
      createdAt={createdAt}
      date={date}
      issuer={issuer}
      authorizedIssuer={authorizedIssuer}
      jurisdiction={jurisdiction}
      legalName={legalName}
      sources={sources}
      summarizedName={summarizedName}
      isExpanded={isExpanded}
      handleEntityClick={handleEntityClick}
      setExpanded={setExpanded}
      industries={industries}
      notesCount={notesCount}
      isCompliant={isCompliant}
      visibleSummary={visibleSummary}
      regulationType={regulationType}
    />
  );
};

export default ResultRegulationCard;
