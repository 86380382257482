import React from 'react';

function SavedSearchs() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3168_16318"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <rect x="0.5" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3168_16318)">
        <path
          d="M13.3747 12.5417L14.2913 11.6667L12.5413 9.91667C12.6941 9.68056 12.8087 9.43056 12.8851 9.16667C12.9615 8.90278 12.9997 8.625 12.9997 8.33333C12.9997 7.51389 12.7115 6.82292 12.1351 6.26042C11.5587 5.69792 10.8747 5.41667 10.083 5.41667C9.29134 5.41667 8.60731 5.69792 8.03093 6.26042C7.45454 6.82292 7.16634 7.51389 7.16634 8.33333C7.16634 9.15278 7.45454 9.84375 8.03093 10.4062C8.60731 10.9688 9.29134 11.25 10.083 11.25C10.3747 11.25 10.649 11.2118 10.9059 11.1354C11.1629 11.059 11.4163 10.9444 11.6663 10.7917L13.3747 12.5417ZM10.083 10C9.62467 10 9.23231 9.83681 8.90593 9.51042C8.57954 9.18403 8.41634 8.79167 8.41634 8.33333C8.41634 7.875 8.57954 7.48264 8.90593 7.15625C9.23231 6.82986 9.62467 6.66667 10.083 6.66667C10.5275 6.66667 10.9163 6.82986 11.2497 7.15625C11.583 7.48264 11.7497 7.875 11.7497 8.33333C11.7497 8.79167 11.5865 9.18403 11.2601 9.51042C10.9337 9.83681 10.5413 10 10.083 10ZM3.83301 15C3.37467 15 2.98231 14.8368 2.65592 14.5104C2.32954 14.184 2.16634 13.7917 2.16634 13.3333V4.16667C2.16634 3.70833 2.32954 3.31597 2.65592 2.98958C2.98231 2.66319 3.37467 2.5 3.83301 2.5H17.1663C17.6247 2.5 18.017 2.66319 18.3434 2.98958C18.6698 3.31597 18.833 3.70833 18.833 4.16667V13.3333C18.833 13.7917 18.6698 14.184 18.3434 14.5104C18.017 14.8368 17.6247 15 17.1663 15H3.83301ZM1.33301 17.5V15.8333H19.6663V17.5H1.33301Z"
          fill="#42474D"
        />
      </g>
    </svg>
  );
}

export default SavedSearchs;
