import React from 'react';
import { BaseCard } from '../BaseCard/BaseCard';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import AiBadge from '../../AiBadge/AiBadge';

import css from './QuoteCard.module.css';
import BriefPill from '../../../../components/BriefPill/BriefPill';
import EventPill from '../../EventPill/EventPill';

/*
  mode = 'list' | 'small-dossier' | 'expanded-dossier'
*/

function QuoteCard({ quote, mode = 'list' }) {
  return (
    <BaseCard.Root data-mode={mode} className={css.dossier}>
      <BaseCard.Row data-mode={mode} className={css.row}>
        <BaseCard.LeftColumn data-mode={mode} className={css.left}>
          <BaseCard.DateRow date={quote.date} />
          <BaseCard.Title data-mode={mode} className={css.title}>
            {`"${quote.quote}"`}
          </BaseCard.Title>
        </BaseCard.LeftColumn>
        <BaseCard.RightColumn data-mode={mode} className={css.right}>
          <BaseCard.InfoColumn title="Quote by">
            <EntityPill
              id={quote.speaker.id}
              image={quote.speaker.image}
              name={quote.speaker.name}
              shortName={quote.speaker.shortestName}
              tooltip={quote.speaker.tooltip}
              type={quote.speaker.type}
              isConnected={quote.speaker.isConnected}
              inSubscription={quote.speaker.inSubscription}
              autoOpenDossier
              size="small"
            />
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Event">
            <EventPill id={quote.event.id} title={quote.event.title} />
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn
            title={
              <div className={css.context}>
                Context <AiBadge />
              </div>
            }
          >
            {quote.context}
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Industries">
            {quote.industries.map((industry) => (
              <BriefPill key={industry.id} name={industry.name} />
            ))}
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Countries">
            {quote.countries.map((country) => (
              <BriefPill key={country.id} name={country.name} isGeography />
            ))}
          </BaseCard.InfoColumn>
        </BaseCard.RightColumn>
      </BaseCard.Row>

      {quote.event.sources && (
        <BaseCard.BottomRow data-mode={mode} className={css.bottom}>
          <BaseCard.SourceList sources={quote.event.sources} />
        </BaseCard.BottomRow>
      )}
    </BaseCard.Root>
  );
}

export default QuoteCard;
