import { gql } from '@apollo/client';

const query = gql`
  query searchEventsAndRegulationsCount(
    $name: String
    $filterIndustries: [ID]
    $topics: [ID]
    $filterEntities: [ID]
    $bookmarked: Boolean
    $connected: Boolean
    $compliance: Boolean
    $notes: Boolean
    $startDate: String
    $endDate: String
  ) {
    searchEventsAndRegulationsCount(
      name: $name
      filterIndustries: $filterIndustries
      topics: $topics
      filterEntities: $filterEntities
      bookmarked: $bookmarked
      connected: $connected
      notes: $notes
      compliance: $compliance
      startDate: $startDate
      endDate: $endDate
    ) {
      events {
        total
      }
      regulations {
        total
      }
      quotes {
        total
      }
      appointments {
        total
      }
      regulationTypesCount {
        type
        total
      }
    }
  }
`;

export default query;
