import { gql } from '@apollo/client';

const query = gql`
  query getUserPdb {
    getUserPdb {
      pdb {
        id
        background
        interests
        industries {
          id
          name
        }
        countries {
          id
          name
        }
        bulletin {
          shortSummary
        }
      }
    }
  }
`;

export default query;
