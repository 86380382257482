export const modalStyles = {
  content: {
    backgroundColor: 'white',
    margin: 'auto',
    height: 'fit-content',
    width: 'fit-content',
    padding: '0px',
    animation: 'zoomModal .2s ease-in-out',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    backdropFilter: 'blur(5px)',
    zIndex: 9999,
  },
};

export const modalStylesCommandKey = {
  content: {
    margin: 'auto',
    width: '600px',
    padding: '0px',
    border: 'none',
    marginTop: '10%',
    position: 'fixed',
    backgroundColor: 'transparent',
    animation: 'zoomModal .2s ease-in-out',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    backdropFilter: 'blur(5px)',
    zIndex: 9,
  },
};
