import React, { useEffect, useState } from 'react';
import {
  getLabelFromType,
  getTotalSearchResults,
  isLoadingResults,
} from '../../../../lib/search';
import css from './SearchHeader.module.css';
import { useSearch } from '../../Search';
import { formatDate } from '../../../../lib/date';
import { useApolloClient } from '../../../../hooks/useApolloClient';
import createSavedSearch from '../../../../queries/createSaveSearch';
import findSavedSearchByData from '../../../../queries/findSavedSearchByData';
import listSavedSearchs from '../../../../queries/listSavedSearches';
import updateSavedSearch from '../../../../queries/updateSaveSearch';
import notify from '../../../../lib/notify';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';

import SavedSearchModal from '../../../../components/SavedSearchModal/SavedSearchModal';
import HomeIcon from '../../../../icons/Home';
import NetworkIcon from '../../../../icons/NetworkIcon/NetworkIcon';
import Tooltip from '../../../../components/Tooltip/Tooltip';

const createSavedSearchMutation = async ({
  client,
  searchName,
  searchData,
}) => {
  return client
    .mutate({
      mutation: createSavedSearch,
      variables: { searchName, searchData },
    })
    .then(({ data }) => data.createSavedSearch);
};

const updateSavedSearchMutation = async ({
  client,
  savedSearchId,
  updatedName,
  updatedData,
}) => {
  return client
    .mutate({
      mutation: updateSavedSearch,
      variables: { savedSearchId, updatedName, updatedData },
    })
    .then(({ data }) => data.updateSavedSearch);
};

const ResultHeaderHighlight = ({ className = '', text, children }) => (
  <span className={`${className} ${css.titleHighlight}`}>
    {text}
    {children}
  </span>
);

const SearchHeaderGroup = ({ results, summaryText, formatResult }) => {
  if (results.length === 1) {
    return <ResultHeaderHighlight text={formatResult(results[0])} />;
  }

  return (
    <ResultHeaderHighlight className={css.titleFilters} text={summaryText}>
      <div className={css.titleFiltersPopover}>
        {results.map((result) => (
          <ResultHeaderHighlight
            key={result.id}
            className={css.titlePopoverHighlight}
            text={formatResult(result)}
          />
        ))}
      </div>
    </ResultHeaderHighlight>
  );
};

const SearchHeader = () => {
  const {
    activeSearchText,
    entityResults,
    facetResults,
    previousState,
    visibleResultType,
    isEntityResultsLoading,
    isFacetResultsLoading,
    setSavedSearches,
    savedSearchId,
    homeFeedSavedSearch,
    setHomeFeedSavedSearch,
    savedSearchName,
    searchTypes,
  } = useSearch();
  const router = useRouteInformation();

  const [openSaveSearchModal, setOpenSaveSearchModal] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState('');

  const [successfulSaveSearch, setSuccessfulSaveSearch] = useState(false);
  const [updateType, setUpdateType] = useState('save');

  const searchDates = previousState?.dates ?? [];
  const searchIndustries = previousState?.industries ?? [];
  const searchEntities = previousState?.entities ?? [];
  const searchCountries = previousState?.countries ?? [];
  const searchLandscapes = [];

  const client = useApolloClient();
  useEffect(() => {
    const searchData = {
      filterEntities: searchEntities.map((entity) => entity.id),
      filterIndustries: searchIndustries.map((industry) => industry.id),
      filterLandscapes:
        (searchLandscapes &&
          searchLandscapes.map((landscape) => landscape.id)) ||
        [],
      name: activeSearchText,
      topics: searchCountries.map((country) => country.id),
      filterCategories: searchTypes,
      searchDates,
      isHomeFeed: false,
    };

    Promise.all([
      client.query({
        query: findSavedSearchByData,
        variables: { searchData: JSON.stringify(searchData) },
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: findSavedSearchByData,
        variables: {
          searchData: JSON.stringify({ ...searchData, isHomeFeed: true }),
        },
        fetchPolicy: 'network-only',
      }),
    ]).then(([{ data }, { data: homeFeedData }]) => {
      const foundSavedSearch =
        data.listSavedSearchs.savedSearches.length > 0 ||
        homeFeedData.listSavedSearchs.savedSearches.length > 0;

      setSuccessfulSaveSearch(foundSavedSearch);

      const homeFeedSavedSearchItem =
        homeFeedData.listSavedSearchs.savedSearches.find(
          (d) => JSON.parse(d.search_data).isHomeFeed,
        );

      setHomeFeedSavedSearch(homeFeedSavedSearchItem);
    });
  }, [
    activeSearchText,
    searchTypes,
    searchIndustries,
    searchLandscapes,
    searchEntities,
    searchCountries,
    searchDates,
  ]);

  const totalResults = getTotalSearchResults({
    entities: entityResults,
    facets: facetResults,
    resultType: visibleResultType,
  });

  const isLoading = isLoadingResults({
    isLoadingFacets: isFacetResultsLoading,
    isLoadingEntities: isEntityResultsLoading,
    resultType: visibleResultType,
  });

  const [startDate, endDate] = searchDates;

  const showType = searchTypes.length > 0;
  const showIndustry = searchIndustries.length > 0;
  const showCountry = searchCountries.length > 0;
  const showEntities = searchEntities.length > 0;
  const showDates = startDate && endDate;

  const handleSaveSearchNameChange = (event) => {
    setSaveSearchName(event.target.value);
  };

  const handleOpenSaveSearchModal = (type) => {
    setUpdateType(type);
    setSaveSearchName(savedSearchName);
    setOpenSaveSearchModal(true);
  };

  const handleCloseSaveSearchModal = () => {
    setOpenSaveSearchModal(false);
  };

  const handleSaveSearch = async (payload) => {
    let result;
    if (savedSearchId) {
      result = await updateSavedSearchMutation({
        client,
        savedSearchId,
        updatedName: saveSearchName || savedSearchName,
        updatedData: JSON.stringify(payload),
      });
    } else {
      result = await createSavedSearchMutation({
        client,
        searchName: saveSearchName,
        searchData: JSON.stringify(payload),
      });
    }

    setOpenSaveSearchModal(false);
    if (result) {
      setSuccessfulSaveSearch(true);
      if (!payload.isHomeFeed) {
        setHomeFeedSavedSearch(null);
      }
      notify.success('Search has been saved!', { position: 'bottom-right' });
      client
        .query({
          query: listSavedSearchs,
          fetchPolicy: 'network-only',
        })
        .then(({ data }) =>
          setSavedSearches(data.listSavedSearchs.savedSearches),
        );
    } else {
      notify.error('Something went wrong!', { position: 'bottom-right' });
    }
  };

  const searchGraphView = () => {
    const data = JSON.stringify({
      filterEntities: searchEntities.map((entity) => entity.id),
      filterIndustries: searchIndustries.map((industry) => industry.id),
      filterLandscapes:
        (searchLandscapes &&
          searchLandscapes.map((landscape) => landscape.id)) ||
        [],
      name: activeSearchText,
      topics: searchCountries.map((country) => country.id),
    });

    router.navigateToSearchView({ data });
  };

  const getNoResultsText = () => {
    if (activeSearchText) return `No results for "${activeSearchText}"`;
    return 'No results';
  };

  if (searchTypes.length === 0) return null;
  if ((isLoading && totalResults === 0) || searchTypes.length === 0)
    return null;

  const isNetworkEnabled =
    searchIndustries?.length > 0 || searchCountries?.length > 0;

  return (
    <div className={css.header}>
      <p data-cy="search-results-title" className={css.title}>
        {!totalResults && getNoResultsText()}
        {totalResults > 0 &&
          (activeSearchText ? `Results for "${activeSearchText}"` : 'Results')}
        {showType && (
          <>
            {' in '}
            <SearchHeaderGroup
              results={searchTypes}
              summaryText={`${searchTypes.length} types`}
              formatResult={getLabelFromType}
            />
          </>
        )}
        {showCountry && (
          <>
            {showType ? ' and ' : ' in '}
            <SearchHeaderGroup
              results={searchCountries}
              summaryText={`${searchCountries.length} countries`}
              formatResult={(country) => country.name}
            />
          </>
        )}
        {showIndustry && (
          <>
            {showCountry || showType ? ' and ' : ' in '}
            <SearchHeaderGroup
              results={searchIndustries}
              summaryText={`${searchIndustries.length} filters`}
              formatResult={(industry) => industry.name}
            />
          </>
        )}
        {showEntities && (
          <>
            {' related to '}
            <SearchHeaderGroup
              results={searchEntities}
              summaryText={`${searchEntities.length} entities`}
              formatResult={(entity) => entity.name}
            />
          </>
        )}
        {showDates && (
          <>
            {' between '}
            <ResultHeaderHighlight
              className={css.titleDatesHighlight}
              text={formatDate(startDate)}
            />
            {' and '}
            <ResultHeaderHighlight
              className={css.titleDatesHighlight}
              text={formatDate(endDate)}
            />
          </>
        )}
      </p>
      <div className={css.saveSearchContainer}>
        <Tooltip
          text={
            !isNetworkEnabled
              ? 'Apply a country or industry filter to see their networks'
              : ''
          }
        >
          <button
            data-cy="see-graph-button"
            type="button"
            data-disabled={!isNetworkEnabled}
            className={css.seeGraph}
            onClick={() => searchGraphView()}
          >
            <span className={css.homeFeed} data-feed-action="set">
              <NetworkIcon className={css.networkIcon} color="#cc0909" /> View
              Network
            </span>
          </button>
        </Tooltip>

        <button
          data-cy="save-search-button"
          type="button"
          className={css.setAsHomeFeed}
          data-status={successfulSaveSearch ? 'saved' : 'default'}
          onClick={() =>
            handleOpenSaveSearchModal(
              successfulSaveSearch && homeFeedSavedSearch
                ? 'removeHomeFeed'
                : 'setHomeFeed',
            )
          }
        >
          <span
            className={css.homeFeed}
            data-feed-action={
              successfulSaveSearch && homeFeedSavedSearch ? 'remove' : 'set'
            }
          >
            <HomeIcon
              className={css.homeIcon}
              color={
                successfulSaveSearch && homeFeedSavedSearch ? '#cc0909' : ''
              }
            />{' '}
            {successfulSaveSearch && homeFeedSavedSearch
              ? 'Remove as home feed'
              : 'Set as home feed'}
          </span>
        </button>
        <button
          data-cy="save-search-button"
          type="button"
          className={css.saveSearch}
          data-status={successfulSaveSearch ? 'saved' : 'default'}
          onClick={() => handleOpenSaveSearchModal('save')}
        >
          {successfulSaveSearch ? '✓ Saved Search' : 'Save Search'}
        </button>
      </div>
      <SavedSearchModal
        activeSearch={activeSearchText}
        openSaveSearchModal={openSaveSearchModal}
        saveSearchName={saveSearchName}
        handleSaveSearchNameChange={handleSaveSearchNameChange}
        handleSaveSearch={handleSaveSearch}
        handleCloseSaveSearchModal={handleCloseSaveSearchModal}
        searchCountries={searchCountries}
        searchTypes={searchTypes}
        searchIndustries={searchIndustries}
        searchEntities={searchEntities}
        searchDates={searchDates}
        searchLandscapes={searchLandscapes}
        updateType={updateType}
      />
    </div>
  );
};

export default SearchHeader;
