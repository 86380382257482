import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { uniqBy } from 'lodash';
import { useApolloClient } from '../../hooks/useApolloClient';
import findSavedSearchByData from '../../queries/findSavedSearchByData';

import css from './Feed.module.css';
import FeedList from './components/FeedList/FeedList';
import FeedHeader from './components/FeedHeader/FeedHeader';
import ToggleSideNav from './components/ToggleSidenav/ToggleSidenav';
import useNavigation from '../../v2/hooks/useNavigation';
import { useSharedState } from '../../contexts/SharedStateContext';
import Filter from '../../v2/components/Filter/Filter';
import {
  ACTIONS,
  filterDefaultState,
  filterReducer,
} from '../../v2/components/Filter/filterReducer';
import {
  categoriesToActiveFilter,
  feedCountToFilterAdapter,
  filterTypes,
  getFilterTypes,
  getIdList,
  getRegulationKey,
  getSelectedValues,
  getValueList,
} from '../../v2/components/Filter';
import { UPDATED_DAYS, fetchFeed } from './api';

const FeedContext = createContext();

const Feed = () => {
  const client = useApolloClient();
  const [savedSearches, setSavedSearches] = useState([]);
  const [savedSearchName, setSavedSearchName] = useState(null);
  const [savedSearchId, setSavedSearchId] = useState(null);
  const [homeFeedSavedSearch, setHomeFeedSavedSearch] = useState(null);
  const [refreshSavedSearches, setRefreshSavedSearches] = useState(false);

  const { params, pushParam, replaceParams } = useNavigation();
  const { selectedSavedSearch, setSelectedSavedSearch } = useSharedState();
  const [pagination, setPagination] = useState({ limit: 10, offset: 0 });
  const [results, setResults] = useState([]);
  const [totals, setTotals] = useState([]);
  const [isResultsLoading, setIsResultsLoading] = useState(false);
  const [filterState, dispatch] = useReducer(filterReducer, filterDefaultState);

  const isFilterOpen = params.get('isFilterOpen');
  const filterStatus = isFilterOpen ? 'expanded' : 'collapsed';

  const normalizeFeedCounts = (total = []) => {
    return total.map((item) => ({
      ...item,
      type: getRegulationKey(item.type),
    }));
  };

  const fetchFeedResults = ({
    entityIds = [],
    resultType,
    countries,
    industries,
    pageInfo,
    regulationTypes,
  }) => {
    let filter = [
      filterTypes.Events,
      filterTypes.Regulations,
      filterTypes.Quotes,
      filterTypes.Appointments,
    ];

    if (resultType !== 'all' && !resultType.includes('all')) {
      if (Array.isArray(resultType)) {
        filter = resultType;
      } else {
        filter = [resultType];
      }
    }

    fetchFeed({
      client,
      topics: countries.length === 0 ? [] : countries,
      entityIds,
      industryIds: industries,
      filter,
      regulationTypes,
      limit: pageInfo.limit,
      offset: pageInfo.offset,
    })
      .then((data) => {
        const { total, updates } = data;
        setTotals(normalizeFeedCounts(total));
        setResults((currentResults) =>
          uniqBy([...currentResults, ...updates], 'id'),
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsResultsLoading(false);
      });
  };

  const resetPagination = () => {
    setResults([]);
    setPagination({ limit: 10, offset: 0 });
  };

  const applySavedSearchFilter = (savedSearch) => {
    setSavedSearchName(savedSearch.name);
    setSavedSearchId(savedSearch.id);
    setHomeFeedSavedSearch(savedSearch.isHomeFeed ? savedSearch : null);

    const searchData = JSON.parse(savedSearch.search_data);
    dispatch({
      type: ACTIONS.UPDATE_ANY,
      payload: {
        entities: searchData.filterEntities,
        countries: searchData.topics,
        industries: searchData.filterIndustries,
        activeFilter: categoriesToActiveFilter(searchData.filterCategories),
      },
    });
  };

  const clearSavedSearchFilter = () => {
    setSavedSearchName(null);
    setHomeFeedSavedSearch(null);
    setSavedSearchId(null);
    dispatch({ type: ACTIONS.UPDATE_ANY, payload: filterDefaultState });
  };

  const onFilterButtonClicked = () => {
    if (params.get('leftPanel')) {
      params.delete('leftPanel');
      params.set('isFilterOpen', true);
      replaceParams(params);
    } else {
      pushParam('isFilterOpen', true);
    }
  };

  const applyFilters = (filters, resetPage = false) => {
    if (resetPage) {
      resetPagination();
    }

    setIsResultsLoading(true);
    fetchFeedResults({
      countries: getIdList(filters.countries),
      industries: getIdList(filters.industries),
      entityIds: getIdList(filters.entities),
      regulationTypes: getValueList(getSelectedValues(filters.regulationTypes)),
      resultType: getFilterTypes(filters),
      pageInfo: pagination,
    });

    dispatch({ type: ACTIONS.APPLY_CHANGES });
  };

  const findSavedSearch = async () => {
    const searchData = {
      filterEntities: filterState.entities.map((entity) => entity.id),
      filterIndustries: filterState.industries.map((industry) => industry.id),
      name: savedSearchName || '',
      topics: filterState.countries.map((country) => country.id),
      filterCategories: filterState.activeFilter,
      searchDates: filterState.dates,
      isHomeFeed: false,
    };

    const [{ data }, { data: homeFeedData }] = await Promise.all([
      client.query({
        query: findSavedSearchByData,
        variables: { searchData: JSON.stringify(searchData) },
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: findSavedSearchByData,
        variables: {
          searchData: JSON.stringify({ ...searchData, isHomeFeed: true }),
        },
        fetchPolicy: 'network-only',
      }),
    ]);

    if (data.listSavedSearchs.savedSearches.length > 0) {
      setSavedSearchName(data.listSavedSearchs.savedSearches[0].name);
      setSavedSearchId(data.listSavedSearchs.savedSearches[0].id);
    }

    const homeFeedSavedSearchItem =
      homeFeedData.listSavedSearchs.savedSearches.find(
        (d) => JSON.parse(d.search_data).isHomeFeed,
      );

    if (homeFeedSavedSearchItem) {
      setHomeFeedSavedSearch(JSON.parse(homeFeedSavedSearchItem.search_data));
      setSavedSearchName(homeFeedSavedSearchItem.name);
      setSavedSearchId(homeFeedSavedSearchItem.id);
    }
  };

  const counts = useMemo(() => {
    return feedCountToFilterAdapter(totals);
  }, [totals]);

  useEffect(() => {
    setIsResultsLoading(true);

    findSavedSearch().then(() => {
      fetchFeedResults({
        entityIds: homeFeedSavedSearch
          ? homeFeedSavedSearch.filterEntities.map((entity) => entity.id)
          : filterState.entities.map((entity) => entity.id),
        resultType: filterState.activeFilter,
        countries: homeFeedSavedSearch
          ? homeFeedSavedSearch.topics.map((country) => country.id)
          : filterState.countries.map((country) => country.id),
        industries: homeFeedSavedSearch
          ? homeFeedSavedSearch.filterIndustries.map((industry) => industry.id)
          : filterState.industries.map((industry) => industry.id),
        pageInfo: { limit: 10, offset: 0 },
        regulationTypes: getValueList(
          getSelectedValues(filterState.regulationTypes),
        ),
      });
    });
  }, []);

  // executed on load more
  useEffect(() => {
    if (pagination.offset === 0) return;
    applyFilters(filterState);
    setIsResultsLoading(true);
  }, [pagination]);

  // This effect runs when user select a saved search on leftPanel
  useEffect(() => {
    if (!selectedSavedSearch || selectedSavedSearch === savedSearchId) return;

    applySavedSearchFilter(selectedSavedSearch);
  }, [selectedSavedSearch]);

  // Remove saved search when unMounting component
  useEffect(() => () => setSelectedSavedSearch(null), []);

  return (
    <FeedContext.Provider
      value={{
        feedEntities: filterState.previousState?.entities ?? [],
        feedResultType: filterState.previousState?.activeFilter || 'all',
        feedIndustries: filterState.previousState?.industries ?? [],
        feedCountries: filterState.previousState?.countries ?? [],
        pagination,
        results,
        totals,
        isResultsLoading,
        savedSearches,
        savedSearchName,
        refreshSavedSearches,
        homeFeedSavedSearch,
        savedSearchId,

        setSavedSearches,
        setSavedSearchName,
        setPagination,
        setHomeFeedSavedSearch,
        setRefreshSavedSearches,
        setSavedSearchId,
        clearSavedSearchFilter,
      }}
    >
      <div data-cy="feed" className={css.root}>
        <div className={css.container}>
          <ToggleSideNav
            filterStatus={filterStatus}
            onClick={onFilterButtonClicked}
          />
          <Filter
            dispatch={dispatch}
            counts={counts}
            dataCy="feed-filters"
            filterState={filterState}
            isFilterOpen={isFilterOpen}
            isLoadingCounts={isResultsLoading}
            onFilterApplied={(data) => applyFilters(data, true)}
          />
          <div
            data-cy="feed-events"
            data-status={filterStatus}
            className={css.content}
          >
            <FeedHeader />
            <FeedList updatedDays={UPDATED_DAYS} />
          </div>
        </div>
      </div>
    </FeedContext.Provider>
  );
};

export default Feed;

export const useFeed = () => useContext(FeedContext);
