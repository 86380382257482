import React from 'react';

import css from './Suggestions.module.css';

function LoadingState() {
  return (
    <div className={css.main}>
      <div className={css.loading}>
        <div style={{ height: 15 }} className="skeleton-v2" />
        <div style={{ height: 15 }} className="skeleton-v2" />
        <div style={{ height: 15 }} className="skeleton-v2" />
        <div style={{ height: 15 }} className="skeleton-v2" />
      </div>
    </div>
  );
}

export default LoadingState;
