/* eslint-disable no-underscore-dangle */
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_NOTE from '../../queries/updateNote';
import GET_ALL_NOTES from '../../queries/getAllNotes';
import TOGGLE_USER_COMPLIANCE from '../../queries/toggleUserCompliance';
import TOGGLE_USER_CONNECTION from '../../queries/toggleUserConnection';
import DELETE_NOTE from '../../queries/deleteNote';

const NoteType = {
  entityNotes: 'entity',
  regulationNotes: 'regulation',
  eventNotes: 'event',
  connectionNotes: 'connection',
  complianceNotes: 'compliance',
};

function useNotes() {
  const { data, loading, refetch } = useQuery(GET_ALL_NOTES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [updateRealNote] = useMutation(UPDATE_NOTE);
  const [updateCompliantNote] = useMutation(TOGGLE_USER_COMPLIANCE);
  const [updateConnectionNote] = useMutation(TOGGLE_USER_CONNECTION);
  const [removeNote] = useMutation(DELETE_NOTE);

  const updateNote = (note) => {
    if (NoteType[note.__typename]) {
      return updateRealNote({
        variables: {
          noteId: note.id,
          note: note.note,
          noteType: NoteType[note.__typename],
        },
      });
    }

    if (note.__typename === 'complianceNotes') {
      return updateCompliantNote({
        variables: {
          regulationId: note.regulation.id,
          note: note.note,
          flag: true,
        },
      });
    }

    if (note.__typename === 'connectionNotes') {
      return updateConnectionNote({
        variables: {
          entityId: note.entity.id,
          note: note.note,
          flag: true,
        },
      });
    }

    return () => {};
  };

  const deleteNote = (note) =>
    removeNote({
      variables: {
        noteId: note.id,
        noteType: NoteType[note.__typename],
      },
    });

  return {
    notes: data?.getAllUserNotes.notes || [],
    loading,
    updateNote,
    refetch,
    deleteNote,
  };
}

export default useNotes;
