import React, { useState } from 'react';
/* eslint-disable no-underscore-dangle */
import { Popover } from 'react-tiny-popover';
import css from './NoteCard.module.css';
import Card from '../../Card/Card';
import IconButton from '../../../IconButton/IconButton';
import EditIcon from '../../../../../icons/Edit';
import DeleteIcon from '../../../../../icons/Trash';
import More from '../../../../../icons/More';
import CompliantBadge from '../../../CompliantBadge/CompliantBadge';
import { formatDateMed } from '../../../../../lib/date';
import EntityPill from '../../../../../components/EntityPill/EntityPill';
import useNavigation from '../../../../hooks/useNavigation';

function NoteCard({ note, onEdit, onDelete, onNoteSelected, selectedNoteId }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    toggleEntityDossier,
    toggleEventDossier,
    toggleRegulationDossier,
    params,
  } = useNavigation();

  const isRegulation = ['regulationNotes', 'complianceNotes'].includes(
    note.__typename,
  );
  const isEntity = ['entityNotes', 'connectionNotes'].includes(note.__typename);
  const isEvent = note.__typename === 'eventNotes';
  const isComplianceNote = ['complianceNotes'].includes(note.__typename);
  const isConnectionNote = ['connectionNotes'].includes(note.__typename);

  const onCardClicked = (noteItem) => {
    onNoteSelected(noteItem.id);

    if (isRegulation) {
      toggleRegulationDossier(noteItem.regulation.id);
      return;
    }

    if (isEvent) {
      toggleEventDossier(noteItem.event.id);
      return;
    }

    if (isEntity) {
      toggleEntityDossier(noteItem.entity.id);
    }
  };

  const isDossierOpen = params.get('dossierType') !== null;

  return (
    <Card
      selected={selectedNoteId === note.id && isDossierOpen}
      onClick={() => onCardClicked(note)}
    >
      <div className={css.flex}>
        <div className={css.header}>
          {isEvent && <h3>{note.event.title}</h3>}
          {isRegulation && <h3>{note.regulation.summarized_name}</h3>}
          {isEntity && (
            <div className={css.entity}>
              <EntityPill
                id={note.entity.id}
                name={note.entity.name}
                type={note.entity.type}
                image={note.entity.image}
                size="small"
                autoOpenDossier
                shortName={note.entity.shortestName}
                isConnected={note.entity.isConnected}
                inSubscription={note.entity.inSubscription}
              />
            </div>
          )}

          <Popover
            isOpen={isMenuOpen}
            positions={['bottom', 'right']}
            containerClassName={css.menu}
            onClickOutside={(e) => {
              e.stopPropagation();
              setIsMenuOpen(!isMenuOpen);
            }}
            content={
              <div>
                <div
                  className={css.menuItem}
                  data-cy="compliant-card-edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMenuOpen(false);
                    onEdit(note);
                  }}
                >
                  <EditIcon className={css.editIcon} /> Edit note
                </div>
                <div
                  className={css.menuItem}
                  data-cy="compliant-card-delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMenuOpen(false);
                    onDelete(note, isComplianceNote, isConnectionNote);
                  }}
                >
                  <DeleteIcon className={css.deleteIcon} /> Delete note
                </div>
              </div>
            }
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsMenuOpen(true);
              }}
            >
              <More />
            </IconButton>
          </Popover>
        </div>

        <p className={css.note}>{note.note}</p>

        {isRegulation && note.regulation.isCompliant && <CompliantBadge />}
      </div>
      <div className={css.divider} />

      <div className={css.date}>{formatDateMed(note.updated_at)}</div>
    </Card>
  );
}

export default NoteCard;
