import React, { useState } from 'react';

import css from './PanelHeader.module.css';
import CloseIcon from '../../../../icons/CloseIcon/CloseIcon';

import Search from '../../../../icons/Search';
import useNavigation from '../../../hooks/useNavigation';

function PanelHeader({ count, onSearch, title }) {
  const { deleteParam } = useNavigation();
  const [value, setValue] = useState('');

  const onInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch(value);
    }
  };

  const cleanSearch = () => {
    setValue('');
    onSearch('');
  };

  return (
    <div className={css.header}>
      <div className={css.firstLine}>
        <aside>
          <h3>{title}</h3>
          <div className={css.count}>{count}</div>
        </aside>

        <button
          type="button"
          className={css.closeBtn}
          onClick={() => deleteParam('leftPanel')}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={css.inputContainer}>
        <Search />
        <input
          data-cy="saved-search-textbox"
          placeholder="Search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={onInputKeyDown}
        />

        {value && (
          <div className={css.cleanInput} onClick={cleanSearch}>
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  );
}

export default PanelHeader;
