import React from 'react';

const NetworkIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 12 12" data-cy="network-icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.68204 0H0V3.60863H3.35653L3.89999 4.36033V7.63967L3.35653 8.39137H0V12H3.68204V8.7603L4.30095 7.90424H7.69904L8.31796 8.7603V12H12V8.39137H8.64346L8.09998 7.63963V4.36037L8.64346 3.60863H12V0H8.31796V3.2397L7.6992 4.09555H4.3008L3.68204 3.2397V0ZM2.76153 1.03104V2.57759H0.920509L0.920509 1.03104H2.76153ZM9.23846 1.03104V2.57759H11.0795V1.03104H9.23846ZM5.09999 5.29555V6.70424H6.89998V5.29555H5.09999ZM2.76153 9.42241V10.969H0.920509L0.920509 9.42241H2.76153ZM9.23846 9.42241V10.969H11.0795V9.42241H9.23846Z"
      fill="#404040"
    />
  </svg>
);

export default NetworkIcon;
