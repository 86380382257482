import React from 'react';

import css from './Home.module.css';
import SearchBar from '../../components/SearchBar/SearchBar';
import TrendingEntities from '../../components/TrendingEntities/TrendingEntities';
import Welcome from '../../../components/Welcome/Welcome';
import TreeMapWrapper from '../../components/TreeMap/TreeMapWrapper';
import DailyBriefWrapper from '../../components/DailyBrief/DailyBriefWrapper';

function Home() {
  return (
    <div className={css.main}>
      <div className={css.welcome}>
        <Welcome />
      </div>
      <div className={css.space}>
        <SearchBar />
      </div>
      <div className={css.pdb}>
        <DailyBriefWrapper />
        <TrendingEntities />
      </div>

      <div className={css.treeMap}>
        <TreeMapWrapper />
      </div>
    </div>
  );
}

export default Home;
