import { useState } from 'react';
import queryEntitesCount from '../queries/searchEntitiesCount';
import queryEventsAndRegulationsCount from '../queries/searchEventsAndRegulationsCount';
import { getRegulationKey } from '../v2/components/Filter';

const fetchSearchEntitiesCount = ({
  client,
  name,
  filterIndustries,
  topics,
  filterEntities,
  bookmarked = false,
  connected = false,
  notes = false,
  compliance = false,
}) => {
  return client
    .query({
      query: queryEntitesCount,
      variables: {
        name,
        filterIndustries,
        filterActorTypes: [
          'actor',
          'people',
          'peopleAtCompany',
          'governments',
          'peopleAtGovernment',
          'peopleAtOrganization',
          'organizations',
          'companies',
          'others',
        ],
        topics,
        filterEntities,
        bookmarked,
        connected,
        notes,
        compliance,
      },
    })
    .then(({ data }) => data.searchEntitiesCount);
};

const fetchSearchFacetResultsCount = ({
  client,
  name,
  filterIndustries,
  topics,
  filterEntities,
  startDate,
  endDate,
  bookmarked = false,
  connected = false,
  notes = false,
  compliance = false,
}) => {
  return client
    .query({
      query: queryEventsAndRegulationsCount,
      variables: {
        name,
        filterIndustries,
        topics,
        filterEntities,
        startDate,
        endDate,
        bookmarked,
        connected,
        notes,
        compliance,
      },
    })
    .then(({ data }) => data.searchEventsAndRegulationsCount);
};

function useSearchCounts() {
  const [counts, setCounts] = useState(null);
  const [isCountLoading, setIsCountLoading] = useState(false);

  const fetchSearchCount = async ({
    client,
    search,
    industries,
    countries,
    entities,
    extraFilter,
    dates,
  }) => {
    if (!isCountLoading) {
      setIsCountLoading(true);
    }

    const variables = {
      name: search,
      filterIndustries: industries,
      topics: countries,
      filterEntities: entities,
      bookmarked: extraFilter === 'bookmarks',
      connected: extraFilter === 'connections',
      notes: extraFilter === 'notes',
      compliance: extraFilter === 'compliant',
    };

    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      variables.startDate = startDate;
      variables.endDate = endDate;
    }

    try {
      const result = await Promise.all([
        fetchSearchEntitiesCount({ client, ...variables }),
        fetchSearchFacetResultsCount({ client, ...variables }),
      ]);

      const updatedCounts = {};

      result[0].categories.forEach((item) => {
        updatedCounts[item.type] = item.total;
      });

      updatedCounts.events = result[1].events.total;
      updatedCounts.regulations = result[1].regulations.total;
      updatedCounts.quotes = result[1].quotes.total;
      updatedCounts.appointments = result[1].appointments.total;

      result[1].regulationTypesCount.forEach((item) => {
        updatedCounts[getRegulationKey(item.type)] = item.total;
      });

      setCounts(updatedCounts);
    } catch (_) {
      setCounts({});
    } finally {
      setIsCountLoading(false);
    }
  };

  return { counts, isCountLoading, fetchSearchCount };
}

export default useSearchCounts;
