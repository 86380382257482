import React from 'react';

const Select = ({ className = '' }) => (
  <svg className={className} viewBox="0 0 12 10" fill="none">
    <path
      d="M12 5.00065L9.1 9.10065C8.97778 9.27843 8.81956 9.41732 8.62533 9.51732C8.43067 9.61732 8.22222 9.66732 8 9.66732H1.33333C0.966667 9.66732 0.652667 9.53687 0.391333 9.27598C0.130444 9.01465 0 8.70065 0 8.33398V1.66732C0 1.30065 0.130444 0.986873 0.391333 0.725984C0.652667 0.464651 0.966667 0.333984 1.33333 0.333984H8C8.22222 0.333984 8.43067 0.383984 8.62533 0.483984C8.81956 0.583984 8.97778 0.722873 9.1 0.900651L12 5.00065ZM10.3667 5.00065L8 1.66732H1.33333V8.33398H8L10.3667 5.00065Z"
      fill="#939393"
    />
  </svg>
);

export default Select;
