export const buildCountryOptions = (countries, activeCountries) => {
  if (!countries) return [];

  const activeCountryIds = new Set(
    activeCountries.map((country) => country.id),
  );

  const sortedCountries = countries.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return sortedCountries.map((country) => ({
    ...country,
    selected: activeCountryIds.has(country.id),
  }));
};
