import { useEffect } from 'react';

const KeyCodes = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ESC: 'Escape',
};

function focusWithoutScroll(element) {
  const scrollable = document.querySelector('#outlet');
  if (!scrollable) return;

  const originalOverflow = scrollable.style.overflow;

  scrollable.style.overflow = 'hidden';
  element.focus();

  // Restore the overflow style after focusing
  setTimeout(() => {
    scrollable.style.overflow = originalOverflow || 'scroll';
  }, 0);
}

function focusElement(element) {
  if (!element) return;

  if ('preventScroll' in document.createElement('div')) {
    // Modern browsers
    element.focus({ preventScroll: true });
  } else {
    focusWithoutScroll(element);
  }
}

const getFocusableElements = (containerRef) =>
  containerRef.current.querySelectorAll('[data-selectable]');

const getCurrentFocusIndex = () =>
  document.activeElement?.attributes['data-selectable']?.value;

function useArrowNaviagtion({ onEscape, containerRef }) {
  const onKeyDown = ({ code }) => {
    if (code === KeyCodes.ARROW_UP) {
      const currentFocus = getCurrentFocusIndex();
      const focusableElements = getFocusableElements(containerRef);

      const nextIndex = Number(currentFocus) - 1;

      if (nextIndex >= 0) {
        const next = focusableElements[nextIndex];
        focusElement(next);
      }
    }

    if (code === KeyCodes.ARROW_DOWN) {
      const currentFocus = getCurrentFocusIndex();
      const focusableElements = getFocusableElements(containerRef);

      const nextIndex = Number(currentFocus) + 1;

      if (nextIndex >= 0) {
        const next = focusableElements[nextIndex];
        focusElement(next);
      }
    }

    if (code === KeyCodes.ESC) {
      const focusableElements = getFocusableElements(containerRef);
      const input = focusableElements[0];
      focusElement(input);
      onEscape();
    }
  };

  useEffect(() => {
    containerRef.current?.addEventListener('keydown', onKeyDown);
    return () =>
      containerRef.current?.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown, containerRef]);
}

export default useArrowNaviagtion;
